const feed408 = `
<feed xmlns="http://www.w3.org/2005/Atom" xmlns:app="http://www.w3.org/2007/app" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:opds="http://opds-spec.org/2010/catalog" xmlns:opf="http://www.idpf.org/2007/opf" xmlns:drm="http://librarysimplified.org/terms/drm" xmlns:schema="http://schema.org/" xmlns:simplified="http://librarysimplified.org/terms/" xmlns:bibframe="http://bibframe.org/vocab/" xmlns:bib="http://bib.schema.org/" xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" xmlns:lcp="http://readium.org/lcp-specs/ns" simplified:entryPoint="http://schema.org/EBook">
  <id>/USOEI/feed/408</id>
  <title>Action &amp; Adventure</title>
  <updated>2022-02-10T04:49:38+00:00</updated>
  <link href="/USOEI/feed/408" rel="self"/>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The 100</title>
    <author>
      <name>Morgan, Kass</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Morgan, Kass" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Morgan%2C%20Kass/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="The 100">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="The 100" href="https://qa-circulation.openebooks.us/USOEI/works/series/The%20100/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">When 100 juvenile delinquents are sent on a mission to recolonize Earth, they get a second chance at freedom, friendship, and love, as they fight to survive in a dangerous new world.</summary>
    <simplified:pwid>cd5d60ae-60d8-9997-19b3-66c7b43f6b3f</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316259002/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316259002/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Dystopian%20SF" label="Dystopian SF"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2014-06-10</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015852744/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0015852744</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015852744" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2016-01-26T00:00:00Z</published>
    <updated>2022-01-14T04:02:33+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015852744/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015852744/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0015852744"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0015852744/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>172 Hours on the Moon</title>
    <author>
      <name>Harstad, Johan</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Harstad, Johan" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Harstad%2C%20Johan/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">In 2019, Mia, Antoine, and Midori are selected by lottery to join astronauts on a NASA mission to the moon base, DARLAH 2, while in a Florida nursing home, a former astronaut struggles to warn someone of the danger there.</summary>
    <simplified:pwid>99dbf097-c68f-5689-e869-ec21578ac4c9</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316202084/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316202084/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Horror" label="Horror"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Science%20Fiction" label="Science Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2012-04-17</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013219734/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013219734</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013219734" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-14T04:01:42+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013219734/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013219734/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013219734"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013219734/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Adaptation</title>
    <author>
      <name>Lo, Malinda</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Lo, Malinda" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Lo%2C%20Malinda/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">In the aftermath of a series of plane crashes caused by birds, 17-year-old Reese and her debate-team partner, David, receive medical treatment at a secret government facility and become tangled in a conspiracy that is, according to Reese's friend Julian, connected with aliens and UFOs. 25,000 first printing.</summary>
    <simplified:pwid>549c9746-0fb6-94b2-f84d-02c458870f78</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316214483/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316214483/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Romance" label="Romance"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Science%20Fiction" label="Science Fiction"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2012-09-18</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215323/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013215323</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215323" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-14T04:01:16+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215323/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215323/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013215323"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013215323/open_book"/>
  </entry>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/408?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full" title="Title" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by" opds:activeFacet="true"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/408?entrypoint=Book&amp;order=author&amp;available=now&amp;collection=full" title="Author" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/408?entrypoint=Book&amp;order=added&amp;available=now&amp;collection=full" title="Recently Added" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link rel="next" href="https://qa-circulation.openebooks.us/USOEI/feed/408?key=page2"/>
  <link rel="start" href="https://qa-circulation.openebooks.us/USOEI/groups/" title="All Books"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/groups/406" rel="up" title="High School"/>
  <simplified:breadcrumbs>
    <link title="High School" href="https://qa-circulation.openebooks.us/USOEI/groups/406"/>
    <link title="Book" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book"/>
  </simplified:breadcrumbs>
  <link rel="http://opds-spec.org/auth/document" href="https://qa-circulation.openebooks.us/USOEI/authentication_document"/>
  <link rel="search" type="application/opensearchdescription+xml" href="https://qa-circulation.openebooks.us/USOEI/search/408?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full"/>
  <link rel="http://opds-spec.org/shelf" type="application/atom+xml;profile=opds-catalog;kind=acquisition" href="https://qa-circulation.openebooks.us/USOEI/loans/"/>
  <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/"/>
  <link href="http://openebooks.net/index.html" title="Home" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/about.html" title="About" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/faq.html" title="FAQs" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/resources.html" title="Resources" type="text/html" rel="related" role="navigation"/>
  <link href="https://openebooks.zendesk.com/" title="Help Center" type="text/html" rel="related" role="navigation"/>
  <link href="mailto:leonardrichardson@nypl.org" rel="help"/>
</feed>
`;

export default feed408;
