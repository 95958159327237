const loans = `
<feed xmlns="http://www.w3.org/2005/Atom" xmlns:app="http://www.w3.org/2007/app" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:opds="http://opds-spec.org/2010/catalog" xmlns:opf="http://www.idpf.org/2007/opf" xmlns:drm="http://librarysimplified.org/terms/drm" xmlns:schema="http://schema.org/" xmlns:simplified="http://librarysimplified.org/terms/" xmlns:bibframe="http://bibframe.org/vocab/" xmlns:bib="http://bib.schema.org/" xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" xmlns:lcp="http://readium.org/lcp-specs/ns">
  <id>https://qa-circulation.openebooks.us/USOEI/loans/</id>
  <title>Active loans and holds</title>
  <updated>2022-12-16T22:18:36+00:00</updated>
  <link href="https://qa-circulation.openebooks.us/USOEI/loans/" rel="self"/>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Mouse and the Moon</title>
    <contributor opf:role="ill">
      <name>Alborozo, Gabriel</name>
    </contributor>
    <author>
      <name>Alborozo, Gabriel</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Alborozo, Gabriel" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Alborozo%2C%20Gabriel/eng/Children"/>
    </author>
    <summary type="html">This is the story of a little mouse who lives in a dark and wild wood.  He is all alone, except for his friend the moon. Each night the mouse  tells the moon all of his news, his hopes, and his fears. But the moon  never replies. So the little mouse ventures out to find his  friend—and  finds someone unexpected instead.Gabriel Alborozo's  beautiful illustrations shift from black and white to color,  celebrating the dawn of a new day and the beginning of a new  friendship.</summary>
    <simplified:pwid>9d24a5fb-f746-6240-a094-e57c6c2c21e4</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250136107/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250136107/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Henry Holt &amp; Co</bib:publisherImprint>
    <dcterms:issued>2016-09-27</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019495277/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0019495277</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019495277" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <link rel="self" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019495277" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2022-12-16T22:08:52+00:00</updated>
    <link type="application/vnd.librarysimplified.axisnow+json" rel="http://opds-spec.org/acquisition" href="https://qa-circulation.openebooks.us/USOEI/works/121721/fulfill/15">
      <opds:availability status="available" since="2022-12-16T22:18:26+00:00" until="2023-01-15T22:18:00+00:00"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link href="https://qa-circulation.openebooks.us/USOEI/loans/121721/revoke" rel="http://librarysimplified.org/terms/rel/revoke"/>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019495277/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0019495277"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0019495277/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Almost Midnight</title>
    <author>
      <name>Hunter, C. C.</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hunter, C. C." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="Shadow Falls: After Dark">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Shadow Falls: After Dark" href="https://qa-circulation.openebooks.us/USOEI/works/series/Shadow%20Falls:%20After%20Dark/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">A vampire and a werewolf who never fit in. A witch whose spells don't always work as planned. And a mysterious new guy who's hiding something. Together they will discover who they're meant to be...Hidden from the human world, Shadow Falls is a secret camp for teens with supernatural powers. But for four very different teens, it's much more than just a camp-it's where their destinies will unfold.Independent and strong-willed Della Tsang didn't believe in vampires...until she became one. Chase Tallman is the newest member of Shadow Falls, but what made him into the sexy, mysterious vampire he is today? And what led him to Della Tsang? For Miranda Kane, magic has always been something she's struggled with-until she finally has a chance to prove herself a witch to be reckoned with. And for Fredericka Lakota, the one guy who challenges her the most will lead her to the life she's always wanted.C. C. Hunter's New York Times bestselling Shadow Falls series captured hearts all over the world. Now available in print for the first time, these enchanting novellas will reveal a side of the camp that you've never seen before...and live in your heart forever. Fans won't want to miss these four remarkable stories of love, magic and friendship in Almost Midnight.</summary>
    <simplified:pwid>e2907ae6-0cff-a26a-90ec-2bb676a39efb</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466892859/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466892859/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Suspense/Thriller" label="Suspense/Thriller"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Paranormal%20Romance" label="Paranormal Romance"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>St Martins Pr Griffin</bib:publisherImprint>
    <dcterms:issued>2016-02-02</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0017409891</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <link rel="self" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2022-12-16T22:09:07+00:00</updated>
    <link type="application/vnd.librarysimplified.axisnow+json" rel="http://opds-spec.org/acquisition" href="https://qa-circulation.openebooks.us/USOEI/works/121907/fulfill/15">
      <opds:availability status="available" since="2022-12-16T21:10:44+00:00" until="2023-01-15T21:10:00+00:00"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link href="https://qa-circulation.openebooks.us/USOEI/loans/121907/revoke" rel="http://librarysimplified.org/terms/rel/revoke"/>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0017409891"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0017409891/open_book"/>
  </entry>
  <simplified:patron simplified:authorizationIdentifier="A3IAYP9NDS"/>
  <link rel="http://opds-spec.org/shelf" type="application/atom+xml;profile=opds-catalog;kind=acquisition" href="https://qa-circulation.openebooks.us/USOEI/loans/"/>
  <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/"/>
  <link href="http://openebooks.net/index.html" title="Home" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/about.html" title="About" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/faq.html" title="FAQs" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/resources.html" title="Resources" type="text/html" rel="related" role="navigation"/>
  <link href="https://openebooks.zendesk.com/" title="Help Center" type="text/html" rel="related" role="navigation"/>
  <link href="mailto:simplyedev@nypl.org" rel="help"/>
  <drm:licensor drm:vendor="NYPL" drm:scheme="http://librarysimplified.org/terms/drm/scheme/ACS">
    <drm:clientToken>USOEI|1671232716|bc424728-fa25-11e8-a43c-0ef6f37def58|mf52OOtrIKjS7ZcpmnIB;nweFyMbxD06swPWGf9EMf0@</drm:clientToken>
  </drm:licensor>
  <link rel="http://librarysimplified.org/terms/rel/user-profile" href="https://qa-circulation.openebooks.us/USOEI/patrons/me/"/>
</feed>

`;

export default loans;
