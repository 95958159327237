const feed407 = `
<feed xmlns="http://www.w3.org/2005/Atom" xmlns:app="http://www.w3.org/2007/app" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:opds="http://opds-spec.org/2010/catalog" xmlns:opf="http://www.idpf.org/2007/opf" xmlns:drm="http://librarysimplified.org/terms/drm" xmlns:schema="http://schema.org/" xmlns:simplified="http://librarysimplified.org/terms/" xmlns:bibframe="http://bibframe.org/vocab/" xmlns:bib="http://bib.schema.org/" xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" xmlns:lcp="http://readium.org/lcp-specs/ns" simplified:entryPoint="http://schema.org/EBook">
  <id>/USOEI/feed/407</id>
  <title>Staff Picks</title>
  <updated>2022-01-04T21:06:47+00:00</updated>
  <link href="/USOEI/feed/407" rel="self"/>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Betrayed</title>
    <author>
      <name>Rush, Jennifer</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Rush, Jennifer" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Rush%2C%20Jennifer/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="Betrayed">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Betrayed" href="https://qa-circulation.openebooks.us/USOEI/works/series/Betrayed/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">After leaving Anna, Sam, Cas, and Nick behind, Trev is on his own and under the watchful eye of the Branch once more. But where do Trev's loyalties really lie? Riley, Trev's overseer, is determined to find out. On Riley's command, Trev sets off on a mission to a small Wisconsin suburb. His order: locate and kill a seemingly innocent teen named Charlie. Trev soon learns, though, not everything is as it seems in this quiet town--most of all Charlie.Find out what Trev's been up to behind the scenes in this Altered Saga original short story.word count: 10,910 words</summary>
    <simplified:pwid>54cb5f1b-1603-ebbb-0877-1aebb2cdf3e3</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316258920/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316258920/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Romance" label="Romance"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2014-08-26</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015470129/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0015470129</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015470129" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:07:26+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015470129/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015470129/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0015470129"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0015470129/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Boy Nobody</title>
    <author>
      <name>Zadoff, Allen</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Zadoff, Allen" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Zadoff%2C%20Allen/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="The Unknown Assassin">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="The Unknown Assassin" href="https://qa-circulation.openebooks.us/USOEI/works/series/The%20Unknown%20Assassin/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">Embarking on a latest mission, 16-year-old Boy Nobody, an assassin under the control of a shadowy government organization known as "The Program," considers sabotaging his work when his target reminds him of the normal life he craves.</summary>
    <simplified:pwid>645c2368-7610-8d7f-06fb-4491fe57d421</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316231121/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316231121/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Mystery" label="Mystery"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2013-06-11</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215711/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013215711</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215711" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:01:12+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215711/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215711/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013215711"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013215711/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Crewel</title>
    <author>
      <name>Albin, Gennifer</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Albin, Gennifer" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Albin%2C%20Gennifer/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="Crewel World">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Crewel World" href="https://qa-circulation.openebooks.us/USOEI/works/series/Crewel%20World/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">Deadly SecretsTangled LiesWoven truthsIncapable. Awkward. Artless. That's what the other girls whisper behind her back. But sixteen-year-old Adelice Lewys has a secret: She wants to fail. Gifted with the ability to weave time with matter, she's exactly what the Guild is looking for, and in the world of Arras, being chosen to work the looms is everything a girl could want. It means privilege, eternal beauty, and being something other than a secretary. It also means the power to manipulate the very fabric of reality. But if controlling what people eat, where they live, and how many children they have is the price of having it all, Adelice isn't interested.Not that her feelings matter, because she slipped and used her hidden talent for a moment. Now she has one hour to eat her mom's overcooked pot roast. One hour to listen to her sister's academy gossip and laugh at her dad's jokes. One hour to pretend everything's okay. And one hour to escape.Because tonight, they'll come for her.</summary>
    <simplified:pwid>68861561-3c0c-ac9b-b135-a33371e72d89</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780374316440/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780374316440/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Dystopian%20SF" label="Dystopian SF"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Farrar Straus &amp; Giroux</bib:publisherImprint>
    <dcterms:issued>2012-10-16</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014706246/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0014706246</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014706246" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:01:52+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014706246/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="10000" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014706246/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0014706246"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0014706246/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Geography of You and Me</title>
    <author>
      <name>Smith, Jennifer E.</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Smith, Jennifer E." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Smith%2C%20Jennifer%20E./eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">"Sparks fly when sixteen-year-old Lucy Patterson and seventeen-year-old Owen Buckley meet on an elevator rendered useless by a New York City blackout. Soon after, the two teenagers leave the city, but as they travel farther away from each other geographically, they stay connected emotionally, in this story set over the course of one year"--</summary>
    <simplified:pwid>65580f8c-bbf5-4e19-2c43-26ea2155a34b</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316254755/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316254755/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Romance" label="Romance"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Poppy</bib:publisherImprint>
    <dcterms:issued>2014-04-15</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013709456/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013709456</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013709456" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:01:39+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013709456/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013709456/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013709456"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013709456/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Heart of a Champion</title>
    <author>
      <name>Deuker, Carl</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Deuker, Carl" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Deuker%2C%20Carl/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">Seth faces a strain on his friendship with Jimmy, who is both a baseball champion and something of an irresponsible fool, when Jimmy is kicked off the team.</summary>
    <simplified:pwid>6056c921-b26d-4534-af51-7c25e0ef9746</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316158411/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316158411/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2009-05-30</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013217610</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:01:23+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013217610"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013217610/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Love Letters to the Dead</title>
    <author>
      <name>Dellaira, Ava</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Dellaira, Ava" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Dellaira%2C%20Ava/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">It begins as an assignment for English class: Write a letter to a dead person. Laurel chooses Kurt Cobain because her sister, May, loved him. And he died young, just like May did. Soon, Laurel has a notebook full of letters to people like Janis Joplin, Amy Winehouse, Amelia Earhart, Heath Ledger, and more -- though she never gives a single one of them to her teacher. She writes about starting high school, navigating new friendships, falling in love for the first time, learning to live with her splintering family. And, finally, about the abuse she suffered while May was supposed to be looking out for her. Only then, once Laurel has written down the truth about what happened to herself, can she truly begin to accept what happened to May. And only when Laurel has begun to see her sister as the person she was -- lovely and amazing and deeply flawed -- can she begin to discover her own path in this stunning debut from Ava Dellaira, Love Letters to the Dead.</summary>
    <simplified:pwid>64e1f367-120f-cbdf-97fb-26d2c152b9f6</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780374346683/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780374346683/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Farrar Straus &amp; Giroux</bib:publisherImprint>
    <dcterms:issued>2014-04-01</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016907770/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016907770</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016907770" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:05:58+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016907770/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="10000" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016907770/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016907770"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016907770/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Lucy Variations</title>
    <author>
      <name>Zarr, Sara</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Zarr, Sara" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Zarr%2C%20Sara/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">"Sixteen-year-old San Franciscan Lucy Beck-Moreau once had a promising future as a concert pianist. Her chance at a career has passed, and she decides to help her ten-year-old piano prodigy brother, Gus, map out his own future, even as she explores why she enjoyed piano in the first place."--</summary>
    <simplified:pwid>ff607088-0998-51a8-4178-ad0e382aa832</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316232005/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316232005/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2013-05-07</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215739/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013215739</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215739" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:01:13+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215739/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215739/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013215739"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013215739/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>A Mango-shaped Space</title>
    <author>
      <name>Mass, Wendy</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Mass, Wendy" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Mass%2C%20Wendy/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">Afraid that she is crazy, thirteen-year-old Mia, who sees a special color with every letter, number, and sound, keeps this a secret until she becomes overwhelmed by school, changing relationships, and the loss of something important to her.</summary>
    <simplified:pwid>f70d0ae3-e1ce-7376-5a9a-c8ab57a97c1b</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316147514/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316147514/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2008-11-16</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013216862/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013216862</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013216862" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:06:45+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013216862/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013216862/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013216862"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013216862/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Of Poseidon</title>
    <author>
      <name>Banks, Anna</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Banks, Anna" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Banks%2C%20Anna/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="The Syrena Legacy">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="The Syrena Legacy" href="https://qa-circulation.openebooks.us/USOEI/works/series/The%20Syrena%20Legacy/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">Galen, a Syrena prince, searches land for a girl he's heard can communicate with fish. It's while Emma is on vacation at the beach that she meets Galen. Although their connection is immediate and powerful, Galen's not fully convinced that Emma's the one he's been looking for. That is, until a deadly encounter with a shark proves  that Emma and her Gift may be the only thing that can save his kingdom. He needs her help--no matter what the risk.</summary>
    <simplified:pwid>eedef090-6e12-dbf6-9b6d-40d1fb65bff0</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466802568/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466802568/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Romance" label="Romance"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Feiwel &amp; Friends</bib:publisherImprint>
    <dcterms:issued>2012-05-22</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014726690/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0014726690</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014726690" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:02:07+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014726690/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="10000" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014726690/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0014726690"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0014726690/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Rapture Practice</title>
    <author>
      <name>Hartzler, Aaron</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hartzler, Aaron" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hartzler%2C%20Aaron/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">A true story describes the author's coming of age in a fervently religious household that believed Jesus' return to Earth to take the faithful to heaven was imminent, recounting how his growing doubts, earthly ties and the sacrifices required by his faith prompted his transformation from conflicted do-gooder to full-fledged teen rebel. 35,000 first printing.</summary>
    <simplified:pwid>8887ff3b-5c2e-f73a-987c-550e29197a11</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316215725/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316215725/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Biography%20%26%20Memoir" label="Biography &amp; Memoir"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Christianity" label="Christianity"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2013-04-09</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215447/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013215447</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215447" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:01:09+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215447/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215447/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013215447"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013215447/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>She Is Not Invisible</title>
    <author>
      <name>Sedgwick, Marcus</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Sedgwick, Marcus" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Sedgwick%2C%20Marcus/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">Laureth Peak's father has taught her to look for recurring events, patterns, and numbers--a skill at which she's remarkably talented. Her secret: She is blind. But when her father goes missing, Laureth and her 7-year-old brother Benjamin are thrust into a mystery that takes them to New York City where surviving will take all her skill at spotting the amazing, shocking, and sometimes dangerous connections in a world full of darkness. Marcus Sedgwick's She Is Not Invisible is an intricate puzzle of a novel that sheds a light on the delicate ties that bind people to each other.This title has Common Core connections.</summary>
    <simplified:pwid>c64e59d7-5a94-7108-f312-ba2628be6c10</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781596438033/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781596438033/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Mystery" label="Mystery"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Roaring Brook</bib:publisherImprint>
    <dcterms:issued>2014-04-22</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017024155/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0017024155</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017024155" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:06:23+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017024155/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="10000" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017024155/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0017024155"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0017024155/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Sweethearts</title>
    <author>
      <name>Zarr, Sara</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Zarr, Sara" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Zarr%2C%20Sara/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">After losing her soul mate, Cameron, when they were nine, Jennifer, now seventeen, transformed herself from the unpopular fat girl into the beautiful and popular Jenna, but Cameron's unexpected return dredges up memories that cause both social and emotional turmoil.</summary>
    <simplified:pwid>6aed92e4-d388-f4b1-3421-49f470146c78</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316141024/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316141024/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2008-02-01</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013213829/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013213829</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013213829" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:01:04+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013213829/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013213829/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013213829"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013213829/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>This Is What Happy Looks Like</title>
    <author>
      <name>Smith, Jennifer E.</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Smith, Jennifer E." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Smith%2C%20Jennifer%20E./eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">"Perfect strangers Graham Larkin and Ellie O'Neill meet online when Graham accidentally sends Ellie an e-mail about his pet pig, Wilbur. The two 17-year-olds strike up an e-mail relationship from opposite sides of the country and don't even know each other's first names. What's more, Ellie doesn't know Graham is a famous actor, and Graham doesn't know about the big secret in Ellie's family tree. When the relationship goes from online to in-person, they find out whether their relationship can be the real thing"--</summary>
    <simplified:pwid>626e0b9d-27cb-6f12-783f-f0febe9e9d8d</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316244213/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316244213/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Contemporary%20Romance" label="Contemporary Romance"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Poppy</bib:publisherImprint>
    <dcterms:issued>2013-04-02</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013214211/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013214211</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013214211" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:01:05+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013214211/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013214211/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013214211"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013214211/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>A Treasury of Miracles for Teens</title>
    <author>
      <name>Kingsbury, Karen</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Kingsbury, Karen" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Kingsbury%2C%20Karen/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">Recounts incidents where Christian teens have felt God's hand--and God's love--at key moments in their lives.</summary>
    <simplified:pwid>e2814a02-2259-ddd2-01b2-a48e48855ad2</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781599956527/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781599956527/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Christianity" label="Christianity"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Faithwords</bib:publisherImprint>
    <dcterms:issued>2008-12-14</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013212229/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013212229</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013212229" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:06:36+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013212229/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013212229/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013212229"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013212229/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>A Walk to Remember</title>
    <author>
      <name>Sparks, Nicholas</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Sparks, Nicholas" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Sparks%2C%20Nicholas/eng/Adult%2CAdults%2BOnly%2CAll%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="Novel Learning Series">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Novel Learning Series" href="https://qa-circulation.openebooks.us/USOEI/works/series/Novel%20Learning%20Series/eng/Adult%2CAdults%2BOnly%2CAll%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">Intersperses grammar and critical thinking problems throughout the novel of first love set in the 1950s that introduces seventeen-year-old Landon, who has never paid much attention to plain Jamie Sullivan, a Baptist minister's daughter, until fate intervenes and Landon learns the true nature of beauty, the joy of giving, and the power of love.</summary>
    <simplified:pwid>d80eb31c-5108-2c35-daa1-66862830a166</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781455588626/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781455588626/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Study%20Aids" label="Study Aids"/>
    <category scheme="http://schema.org/audience" term="Adult" label="Adult"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Grand Central Pub</bib:publisherImprint>
    <dcterms:issued>2014-09-09</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015155659/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0015155659</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015155659" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:02:13+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015155659/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015155659/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0015155659"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0015155659/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>What Happens Next</title>
    <author>
      <name>Clayton, Colleen</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Clayton, Colleen" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Clayton%2C%20Colleen/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">The stress of hiding a horrific incident that she can neither remember nor completely forget leads 16-year-old Cassidy "Sid" Murphy to become alienated from her friends, obsess about weight loss and draw close to Corey "The Living Stoner" Livingston. 30,000 first printing.</summary>
    <simplified:pwid>b3a24fc5-7f35-172e-26e6-3ccec3aa7104</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316215084/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316215084/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Romance" label="Romance"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Poppy</bib:publisherImprint>
    <dcterms:issued>2012-10-09</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215359/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013215359</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215359" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:01:08+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215359/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215359/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013215359"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013215359/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Winner's Curse</title>
    <author>
      <name>Rutkoski, Marie</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Rutkoski, Marie" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Rutkoski%2C%20Marie/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="The Winner's Trilogy">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="The Winner's Trilogy" href="https://qa-circulation.openebooks.us/USOEI/works/series/The%20Winner%27s%20Trilogy/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">A NEW YORK TIMES BESTSELLERWinning what you want may cost you everything you loveThey were never meant to be together. As a general's daughter, seventeen-year-old Kestrel enjoys an extravagant and privileged life. Arin has nothing but the clothes on his back. Then Kestrel makes an impulsive decision that binds Arin to her. Though they try to fight it, they can't help but fall in love. In order to be together, they must betray their people . . . but to be loyal to their country, they must betray each other.Set in a new world, The Winner's Curse is a story of rebellion, duels, ballroom dances, wicked rumors, dirty secrets, and games where everything is at stake, and the gamble is whether you will keep your head or lose your heart.</summary>
    <simplified:pwid>16bd6632-3aab-2c8e-f9e7-881bec1de006</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780374384685/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780374384685/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Farrar Straus &amp; Giroux</bib:publisherImprint>
    <dcterms:issued>2014-03-04</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016719145/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016719145</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016719145" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:05:57+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016719145/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="10000" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016719145/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016719145"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016719145/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Winter Town</title>
    <author>
      <name>Emond, Stephen</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Emond, Stephen" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Emond%2C%20Stephen/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">Evan and Lucy, childhood best friends who grew apart after years of seeing one another only during Christmas break, begin a romance at age seventeen but his choice to mindlessly follow his father's plans for an Ivy League education rather than becoming the cartoonist he longs to be, and her more destructive choices in the wake of family problems, pull them apart.</summary>
    <simplified:pwid>0917e4d2-61ea-bb21-bee1-e7fa8a45b88c</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316194631/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316194631/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Comics%20%26%20Graphic%20Novels" label="Comics &amp; Graphic Novels"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2011-12-05</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013219598/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013219598</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013219598" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T20:00:24+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013219598/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013219598/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013219598"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013219598/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Young World</title>
    <author>
      <name>Weitz, Chris</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Weitz, Chris" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Weitz%2C%20Chris/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="Young World">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Young World" href="https://qa-circulation.openebooks.us/USOEI/works/series/Young%20World/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">Jefferson, with his childhood friend Donna, leads a tribe of teenagers in New York City on a dangerous quest to find an antidote for a mysterious illness that wiped out all adults and children.</summary>
    <simplified:pwid>13032b33-d31e-151f-c740-370fc5e54eef</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316364867/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316364867/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Dystopian%20SF" label="Dystopian SF"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2014-07-29</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014003632/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0014003632</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014003632" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:01:42+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014003632/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014003632/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0014003632"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0014003632/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Zom-b</title>
    <author>
      <name>Shan, Darren</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Shan, Darren" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Shan%2C%20Darren/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">Forced to tolerate his abusive father's racist views and venting his own frustrations by clashing with the kids at school, B wonders about a zombie outbreak in Ireland and finds himself struggling for survival while forging precarious alliances in the serpentine corridors of his high school. 75,000 first printing. By the best-selling author of Cirque du Freak.</summary>
    <simplified:pwid>22742139-e36a-8aaa-9b6b-6f1994e71197</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316217071/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316217071/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Horror" label="Horror"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2012-10-16</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215595/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013215595</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215595" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2022-01-04T04:01:11+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215595/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215595/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013215595"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013215595/open_book"/>
  </entry>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/407?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full" title="Title" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by" opds:activeFacet="true"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/407?entrypoint=Book&amp;order=author&amp;available=now&amp;collection=full" title="Author" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/407?entrypoint=Book&amp;order=added&amp;available=now&amp;collection=full" title="Recently Added" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link rel="next" href="https://qa-circulation.openebooks.us/USOEI/feed/407?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full&amp;key=%5B%22%5Cu2da2%5Cu5041%5Cu21c5%5Cu3010%5Cu480e%5Cu7020%5Cu0000%5Cu0000%22%2C+%22%5Cu269b%5Cu4a50%5Cu60c0%5Cu42f2%5Cu4a5a%5Cu2cc5%5Cu0602%5Cu1001%5Cu6e60%5Cu7702%5Cu2000%5Cu0001%22%2C+1106%5D&amp;size=50"/>
  <link rel="start" href="https://qa-circulation.openebooks.us/USOEI/groups/" title="All Books"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/groups/406" rel="up" title="High School"/>
  <simplified:breadcrumbs>
    <link title="High School" href="https://qa-circulation.openebooks.us/USOEI/groups/406"/>
    <link title="Book" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book"/>
  </simplified:breadcrumbs>
  <link rel="http://opds-spec.org/auth/document" href="https://qa-circulation.openebooks.us/USOEI/authentication_document"/>
  <link rel="search" type="application/opensearchdescription+xml" href="https://qa-circulation.openebooks.us/USOEI/search/407?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full"/>
  <link rel="http://opds-spec.org/shelf" type="application/atom+xml;profile=opds-catalog;kind=acquisition" href="https://qa-circulation.openebooks.us/USOEI/loans/"/>
  <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/"/>
  <link href="http://openebooks.net/index.html" title="Home" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/about.html" title="About" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/faq.html" title="FAQs" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/resources.html" title="Resources" type="text/html" rel="related" role="navigation"/>
  <link href="https://openebooks.zendesk.com/" title="Help Center" type="text/html" rel="related" role="navigation"/>
  <link href="mailto:leonardrichardson@nypl.org" rel="help"/>
</feed>
`;

export default feed407;
