const feed408page2 = `
<feed xmlns="http://www.w3.org/2005/Atom" xmlns:app="http://www.w3.org/2007/app" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:opds="http://opds-spec.org/2010/catalog" xmlns:opf="http://www.idpf.org/2007/opf" xmlns:drm="http://librarysimplified.org/terms/drm" xmlns:schema="http://schema.org/" xmlns:simplified="http://librarysimplified.org/terms/" xmlns:bibframe="http://bibframe.org/vocab/" xmlns:bib="http://bib.schema.org/" xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" xmlns:lcp="http://readium.org/lcp-specs/ns" simplified:entryPoint="http://schema.org/EBook">
  <id>/USOEI/feed/408</id>
  <title>Action &amp; Adventure</title>
  <updated>2022-02-10T05:15:12+00:00</updated>
  <link href="/USOEI/feed/408" rel="self"/>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Death of Attila</title>
    <author>
      <name>Holland, Cecelia</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Holland, Cecelia" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Holland%2C%20Cecelia/eng/Adult%2CAdults%2BOnly%2CAll%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">&amp;lt;div&amp;gt;In&amp;amp;#160;&amp;lt;i&amp;gt;The Death of Attila&amp;lt;/i&amp;gt;, the great Hun leader dominates the late Roman world; in his shadow, a Hun warrior and a German princeling form a fragile comradeship. When Attila dies, the world around them crumbles, and the two men face terrible choices.&amp;lt;/div&amp;gt;</summary>
    <simplified:pwid>45aed929-da9a-ebe9-8b2f-4202289fe9e3</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://covers.nypl.org/Content%20Cafe/ISBN/9781497624795/cover.jpg" type="image/jpeg"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://covers.nypl.org/scaled/300/Content%20Cafe/ISBN/9781497624795/cover.jpg" type="image/jpeg"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Historical%20Fiction" label="Historical Fiction"/>
    <category scheme="http://schema.org/audience" term="Adult" label="Adult"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Open Road Media</dcterms:publisher>
    <bib:publisherImprint>Open Road Media</bib:publisherImprint>
    <dcterms:issued>2014-04-01</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014750058/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0014750058</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014750058" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2016-12-06T00:00:00Z</published>
    <updated>2022-01-14T04:22:08+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014750058/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014750058/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0014750058"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0014750058/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Defy the Stars</title>
    <author>
      <name>Gray, Claudia</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Gray, Claudia" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Gray%2C%20Claudia/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="Defy the Stars">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Defy the Stars" href="https://qa-circulation.openebooks.us/USOEI/works/series/Defy%20the%20Stars/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">Teenaged soldier Noemi and an enemy robot, Abel, who is programmed to obey her commands, set out on an interstellar quest to save her home planet, Earth colony Genesis.</summary>
    <simplified:pwid>0c155c35-91df-3bc9-6b0e-09d250384cde</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316394062/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316394062/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Romance" label="Romance"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Space%20Opera" label="Space Opera"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2017-04-04</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019336916/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0019336916</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019336916" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2017-12-19T00:00:00Z</published>
    <updated>2022-01-14T04:24:47+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019336916/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019336916/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0019336916"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0019336916/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Demon Thief</title>
    <author>
      <name>Shan, Darren</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Shan, Darren" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Shan%2C%20Darren/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="The Demonata">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="The Demonata" href="https://qa-circulation.openebooks.us/USOEI/works/series/The%20Demonata/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">With the opening of a window into a demon world, a boy discovers his powers as a Disciple and his mission to hunt the viciously powerful Demonata to the death.</summary>
    <simplified:pwid>14514aee-8cac-efd3-ab28-529e2a2a4677</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316146609/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316146609/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Folklore" label="Folklore"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Horror" label="Horror"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2008-08-01</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013216790/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013216790</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013216790" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2016-01-27T00:00:00Z</published>
    <updated>2022-01-14T04:01:29+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013216790/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013216790/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013216790"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013216790/open_book"/>
  </entry>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/408?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full" title="Title" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by" opds:activeFacet="true"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/408?entrypoint=Book&amp;order=author&amp;available=now&amp;collection=full" title="Author" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/408?entrypoint=Book&amp;order=added&amp;available=now&amp;collection=full" title="Recently Added" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link rel="next" href="https://qa-circulation.openebooks.us/USOEI/feed/408?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full&amp;key=%5B%22%5Cu1c96%5Cu5150%5Cu69a0%5Cu1090%5Cu0ee0%5Cu2000%5Cu0001%22%2C+%22%5Cu26a7%5Cu514d%5Cu67e0%5Cu6044%5Cu094a%5Cu2cd4%5Cu5296%5Cu314f%5Cu0089%5Cu4077%5Cu181b%5Cu40b0%5Cu0000%5Cu0000%22%2C+622%5D&amp;size=50"/>
  <link rel="start" href="https://qa-circulation.openebooks.us/USOEI/groups/" title="All Books"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/groups/406" rel="up" title="High School"/>
  <simplified:breadcrumbs>
    <link title="High School" href="https://qa-circulation.openebooks.us/USOEI/groups/406"/>
    <link title="Book" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book"/>
  </simplified:breadcrumbs>
  <link rel="http://opds-spec.org/auth/document" href="https://qa-circulation.openebooks.us/USOEI/authentication_document"/>
  <link rel="search" type="application/opensearchdescription+xml" href="https://qa-circulation.openebooks.us/USOEI/search/408?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full"/>
  <link rel="http://opds-spec.org/shelf" type="application/atom+xml;profile=opds-catalog;kind=acquisition" href="https://qa-circulation.openebooks.us/USOEI/loans/"/>
  <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/"/>
  <link href="http://openebooks.net/index.html" title="Home" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/about.html" title="About" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/faq.html" title="FAQs" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/resources.html" title="Resources" type="text/html" rel="related" role="navigation"/>
  <link href="https://openebooks.zendesk.com/" title="Help Center" type="text/html" rel="related" role="navigation"/>
  <link href="mailto:leonardrichardson@nypl.org" rel="help"/>
</feed>

`;

export default feed408page2;
