const bookAlmostMidnightPackage = `<?xml version="1.0" encoding="UTF-8"?>
<package xmlns="http://www.idpf.org/2007/opf" version="3.0" xml:lang="en" unique-identifier="pub-id"
	prefix="rendition: http://www.idpf.org/vocab/rendition/#">
	<metadata xmlns:dc="http://purl.org/dc/elements/1.1/">
		<dc:title id="title">Almost Midnight</dc:title>
		<dc:identifier id="pub-id">urn:isbn:9781466892859</dc:identifier>
		<dc:language>en-US</dc:language>
		<dc:creator id="creator">C. C. Hunter</dc:creator>
		<dc:publisher>St. Martin&#x2019;s Press</dc:publisher>
		<dc:rights>All rights reserved</dc:rights>
		<meta property="dcterms:modified">2015-12-12T16:24:42Z</meta>
		<meta property="dcterms:creator" id="auth">C. C. Hunter</meta>
		<meta property="dcterms:date">2015</meta>
		<meta property="rendition:orientation">auto</meta>
		<meta property="rendition:spread">auto</meta>
		<meta name="cover" content="cover-image" />
	</metadata>
	<manifest>
		<item properties="nav" id="nav" href="nav.xhtml" media-type="application/xhtml+xml" />
		<item id="toc" href="toc.ncx" media-type="application/x-dtbncx+xml" />
		<item id="html-cover-page" href="xhtml/cover.xhtml" media-type="application/xhtml+xml" />
		<item id="tit" href="xhtml/title.xhtml" media-type="application/xhtml+xml" />
		<item id="mini_toc" href="xhtml/mini_toc.xhtml" media-type="application/xhtml+xml" />
		<item id="copyrightnotice" href="xhtml/copyrightnotice.xhtml"
			media-type="application/xhtml+xml" />
		<item id="reader" href="xhtml/reader.xhtml" media-type="application/xhtml+xml" />
		<item id="part1" href="xhtml/part1.xhtml" media-type="application/xhtml+xml" />
		<item id="part2" href="xhtml/part2.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter1" href="xhtml/chapter1.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter2" href="xhtml/chapter2.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter3" href="xhtml/chapter3.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter4" href="xhtml/chapter4.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter5" href="xhtml/chapter5.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter6" href="xhtml/chapter6.xhtml" media-type="application/xhtml+xml" />
		<item id="part3" href="xhtml/part3.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter7" href="xhtml/chapter7.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter8" href="xhtml/chapter8.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter9" href="xhtml/chapter9.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter10" href="xhtml/chapter10.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter11" href="xhtml/chapter11.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter12" href="xhtml/chapter12.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter13" href="xhtml/chapter13.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter14" href="xhtml/chapter14.xhtml" media-type="application/xhtml+xml" />
		<item id="part4" href="xhtml/part4.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter15" href="xhtml/chapter15.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter16" href="xhtml/chapter16.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter17" href="xhtml/chapter17.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter18" href="xhtml/chapter18.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter19" href="xhtml/chapter19.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter20" href="xhtml/chapter20.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter21" href="xhtml/chapter21.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter22" href="xhtml/chapter22.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter23" href="xhtml/chapter23.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter24" href="xhtml/chapter24.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter25" href="xhtml/chapter25.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter26" href="xhtml/chapter26.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter27" href="xhtml/chapter27.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter28" href="xhtml/chapter28.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter29" href="xhtml/chapter29.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter30" href="xhtml/chapter30.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter31" href="xhtml/chapter31.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter32" href="xhtml/chapter32.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter33" href="xhtml/chapter33.xhtml" media-type="application/xhtml+xml" />
		<item id="part5" href="xhtml/part5.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter34" href="xhtml/chapter34.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter35" href="xhtml/chapter35.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter36" href="xhtml/chapter36.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter37" href="xhtml/chapter37.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter38" href="xhtml/chapter38.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter39" href="xhtml/chapter39.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter40" href="xhtml/chapter40.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter41" href="xhtml/chapter41.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter42" href="xhtml/chapter42.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter43" href="xhtml/chapter43.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter44" href="xhtml/chapter44.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter45" href="xhtml/chapter45.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter46" href="xhtml/chapter46.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter47" href="xhtml/chapter47.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter48" href="xhtml/chapter48.xhtml" media-type="application/xhtml+xml" />
		<item id="chapter49" href="xhtml/chapter49.xhtml" media-type="application/xhtml+xml" />
		<item id="backad" href="xhtml/backad.xhtml" media-type="application/xhtml+xml" />
		<item id="abouttheauthor" href="xhtml/abouttheauthor.xhtml"
			media-type="application/xhtml+xml" />
		<item id="adcard" href="xhtml/adcard.xhtml" media-type="application/xhtml+xml" />
		<item id="praise" href="xhtml/praise.xhtml" media-type="application/xhtml+xml" />
		<item id="newsletter" href="xhtml/newsletter.xhtml" media-type="application/xhtml+xml" />
		<item id="contents" href="xhtml/contents.xhtml" media-type="application/xhtml+xml" />
		<item id="copyright" href="xhtml/copyright.xhtml" media-type="application/xhtml+xml" />
		<item id="css" href="styles/stylesheet.css" media-type="text/css" />
		<item id="cover-image" href="images/9781466892859.jpg" media-type="image/jpeg" />
		<item id="image-001" href="images/62623_ch00_6P_fmt.jpeg" media-type="image/jpeg" />
		<item id="image-002" href="images/Author.jpg" media-type="image/jpeg" />
		<item id="image-003" href="images/Shadow_Falls_After_Dar_fmt.jpeg" media-type="image/jpeg" />
		<item id="image-004" href="images/ShadowFalls_Hunter_Ser_fmt.jpeg" media-type="image/jpeg" />
		<item id="image-005" href="images/NewsletterSignup.jpg" media-type="image/jpeg" />
		<item id="image-006" href="images/fb-logo.png" media-type="image/png" />
		<item id="image-007" href="images/twitter_logo.png" media-type="image/png" />
	</manifest>
	<spine toc="toc" page-progression-direction="ltr">
		<itemref idref="html-cover-page" />
		<itemref idref="tit" />
		<itemref idref="mini_toc" />
		<itemref idref="copyrightnotice" />
		<itemref idref="reader" />
		<itemref idref="part1" />
		<itemref idref="part2" />
		<itemref idref="chapter1" />
		<itemref idref="chapter2" />
		<itemref idref="chapter3" />
		<itemref idref="chapter4" />
		<itemref idref="chapter5" />
		<itemref idref="chapter6" />
		<itemref idref="part3" />
		<itemref idref="chapter7" />
		<itemref idref="chapter8" />
		<itemref idref="chapter9" />
		<itemref idref="chapter10" />
		<itemref idref="chapter11" />
		<itemref idref="chapter12" />
		<itemref idref="chapter13" />
		<itemref idref="chapter14" />
		<itemref idref="part4" />
		<itemref idref="chapter15" />
		<itemref idref="chapter16" />
		<itemref idref="chapter17" />
		<itemref idref="chapter18" />
		<itemref idref="chapter19" />
		<itemref idref="chapter20" />
		<itemref idref="chapter21" />
		<itemref idref="chapter22" />
		<itemref idref="chapter23" />
		<itemref idref="chapter24" />
		<itemref idref="chapter25" />
		<itemref idref="chapter26" />
		<itemref idref="chapter27" />
		<itemref idref="chapter28" />
		<itemref idref="chapter29" />
		<itemref idref="chapter30" />
		<itemref idref="chapter31" />
		<itemref idref="chapter32" />
		<itemref idref="chapter33" />
		<itemref idref="part5" />
		<itemref idref="chapter34" />
		<itemref idref="chapter35" />
		<itemref idref="chapter36" />
		<itemref idref="chapter37" />
		<itemref idref="chapter38" />
		<itemref idref="chapter39" />
		<itemref idref="chapter40" />
		<itemref idref="chapter41" />
		<itemref idref="chapter42" />
		<itemref idref="chapter43" />
		<itemref idref="chapter44" />
		<itemref idref="chapter45" />
		<itemref idref="chapter46" />
		<itemref idref="chapter47" />
		<itemref idref="chapter48" />
		<itemref idref="chapter49" />
		<itemref idref="backad" />
		<itemref idref="abouttheauthor" />
		<itemref idref="adcard" />
		<itemref idref="praise" />
		<itemref idref="newsletter" />
		<itemref idref="contents" linear="no" />
		<itemref idref="copyright" />
	</spine>
	<guide>
		<reference type="toc" title="Contents" href="xhtml/contents.xhtml" />
		<reference type="cover" title="Cover Image" href="xhtml/cover.xhtml" />
	</guide>
</package>`;
export default bookAlmostMidnightPackage;
