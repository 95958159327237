const bookHeartOfAChampion = `
<?xml version="1.0" encoding="UTF-8"?>
<entry xmlns="http://www.w3.org/2005/Atom" xmlns:app="http://www.w3.org/2007/app" xmlns:bib="http://bib.schema.org/" xmlns:bibframe="http://bibframe.org/vocab/" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:drm="http://librarysimplified.org/terms/drm" xmlns:lcp="http://readium.org/lcp-specs/ns" xmlns:opds="http://opds-spec.org/2010/catalog" xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" xmlns:opf="http://www.idpf.org/2007/opf" xmlns:schema="http://schema.org/" xmlns:simplified="http://librarysimplified.org/terms/" schema:additionalType="http://schema.org/EBook">
   <title>Heart of a Champion</title>
   <author>
      <name>Deuker, Carl</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Deuker, Carl" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Deuker%2C%20Carl/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
   </author>
   <summary type="html">Seth faces a strain on his friendship with Jimmy, who is both a baseball champion and something of an irresponsible fool, when Jimmy is kicked off the team.</summary>
   <simplified:pwid>6056c921-b26d-4534-af51-7c25e0ef9746</simplified:pwid>
   <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316158411/Large/Logo" type="image/png" />
   <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316158411/Medium/Logo" type="image/png" />
   <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
   <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
   <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14" />
   <dcterms:language>en</dcterms:language>
   <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
   <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
   <dcterms:issued>2009-05-30</dcterms:issued>
   <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610/report" />
   <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013217610</id>
   <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610" type="application/atom+xml;type=entry;profile=opds-catalog" />
   <bibframe:distribution bibframe:ProviderName="Axis 360" />
   <published>2015-09-01T00:00:00Z</published>
   <updated>2021-12-29T04:01:29+00:00</updated>
   <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
      <opds:availability status="available" />
      <opds:holds total="0" />
      <opds:copies total="9999" available="9999" />
   </link>
   <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610/related_books" />
   <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013217610" />
   <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013217610/open_book" />
</entry>
`;

export default bookHeartOfAChampion;
