const feed440 = `
<feed xmlns="http://www.w3.org/2005/Atom" xmlns:app="http://www.w3.org/2007/app" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:opds="http://opds-spec.org/2010/catalog" xmlns:opf="http://www.idpf.org/2007/opf" xmlns:drm="http://librarysimplified.org/terms/drm" xmlns:schema="http://schema.org/" xmlns:simplified="http://librarysimplified.org/terms/" xmlns:bibframe="http://bibframe.org/vocab/" xmlns:bib="http://bib.schema.org/" xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" xmlns:lcp="http://readium.org/lcp-specs/ns" simplified:entryPoint="http://schema.org/EBook">
  <id>/USOEI/feed/440</id>
  <title>Books for integration testing</title>
  <updated>2022-01-04T22:30:13+00:00</updated>
  <link href="/USOEI/feed/440" rel="self"/>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Almost Midnight</title>
    <author>
      <name>Hunter, C. C.</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hunter, C. C." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="Shadow Falls: After Dark">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Shadow Falls: After Dark" href="https://qa-circulation.openebooks.us/USOEI/works/series/Shadow%20Falls:%20After%20Dark/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">A vampire and a werewolf who never fit in. A witch whose spells don't always work as planned. And a mysterious new guy who's hiding something. Together they will discover who they're meant to be...Hidden from the human world, Shadow Falls is a secret camp for teens with supernatural powers. But for four very different teens, it's much more than just a camp-it's where their destinies will unfold.Independent and strong-willed Della Tsang didn't believe in vampires...until she became one. Chase Tallman is the newest member of Shadow Falls, but what made him into the sexy, mysterious vampire he is today? And what led him to Della Tsang? For Miranda Kane, magic has always been something she's struggled with-until she finally has a chance to prove herself a witch to be reckoned with. And for Fredericka Lakota, the one guy who challenges her the most will lead her to the life she's always wanted.C. C. Hunter's New York Times bestselling Shadow Falls series captured hearts all over the world. Now available in print for the first time, these enchanting novellas will reveal a side of the camp that you've never seen before...and live in your heart forever. Fans won't want to miss these four remarkable stories of love, magic and friendship in Almost Midnight.</summary>
    <simplified:pwid>e2907ae6-0cff-a26a-90ec-2bb676a39efb</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466892859/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466892859/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Suspense/Thriller" label="Suspense/Thriller"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Paranormal%20Romance" label="Paranormal Romance"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>St Martins Pr Griffin</bib:publisherImprint>
    <dcterms:issued>2016-02-02</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0017409891</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2022-01-04T14:30:17+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0017409891"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0017409891/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>A Boy, a Ball, and a Dog</title>
    <author>
      <name>Marino, Gianna</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Marino, Gianna" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Marino%2C%20Gianna/eng/Children"/>
    </author>
    <summary type="html">There was never a ball the boy wouldn't throw.Luckily, there was never a ball that his dog couldn't catch.When  a new kind of ball (a balloon)  floats into the picture, both boy and  dog try to find a way to play with it, and they become separated. Will  they find their way back to each other and finish their game?</summary>
    <simplified:pwid>eb176ce9-ceb0-7637-658c-3a32494f416a</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781626728271/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781626728271/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Roaring Brook</bib:publisherImprint>
    <dcterms:issued>2016-06-21</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018923188/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0018923188</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018923188" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2022-01-04T04:24:40+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018923188/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018923188/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0018923188"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0018923188/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>D Is for Drama</title>
    <author>
      <name>Whittemore, Jo</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Whittemore, Jo" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Whittemore%2C%20Jo/eng/Children"/>
    </author>
    <schema:Series name="Mix">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Mix" href="https://qa-circulation.openebooks.us/USOEI/works/series/Mix/eng/Children"/>
    </schema:Series>
    <summary type="html">A lead role means lots of drama in this sparkling story of one tween&amp;amp;;s efforts to shine in the spotlight. Sunny Kim is done with one-line roles at Carnegie Arts Academy&amp;amp;;she&amp;amp;;s ready for the lead. But even after a summer of studying with an acting coach, Sunny doesn&amp;amp;;t snag the role of Mary Poppins in her school&amp;amp;;s upcoming production. &amp;amp;nbsp;&amp;amp;nbsp;&amp;amp;nbsp;&amp;amp;nbsp; Unfortunately, her entire family mistakenly thinks otherwise, including her former-actress mother. &amp;amp;nbsp;&amp;amp;nbsp;&amp;amp;nbsp;&amp;amp;nbsp; Desperate for a solution, Sunny convinces her theater adviser to let her produce a one-woman show. But when the rest of her friends find out&amp;amp;;the friends that never seem to make the playbill either&amp;amp;;they all want to join in. Before long, Sunny is knee-deep in curtains, catfights, and chorus lines as her one-woman work turns into a staging of the hit musical Wicked. And when a terrible misunderstanding pits the entire cast against Sunny, can the show&amp;amp;;and Sunny&amp;amp;;s future acting career&amp;amp;;be saved in time for opening night?</summary>
    <simplified:pwid>0912a4f7-ff6b-254d-515c-ef22e7d397c8</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442441538/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442441538/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Humorous%20Fiction" label="Humorous Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
    <bib:publisherImprint>Aladdin</bib:publisherImprint>
    <dcterms:issued>2012-08-07</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686310/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016686310</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686310" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2016-01-18T00:00:00Z</published>
    <updated>2022-01-04T04:10:00+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686310/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686310/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016686310"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016686310/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Good Night, Baby Animals You've Had a Busy Day</title>
    <contributor opf:role="ill">
      <name>Watkins, Laura</name>
    </contributor>
    <author>
      <name>Winnick, Karen B.</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Winnick, Karen B." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Winnick%2C%20Karen%20B./eng/Children"/>
    </author>
    <summary type="html">Whether they're dashing, splashing, munching, or crunching, baby animals  are ever-exploring! At home in their natural habitats, baby animals  learn about the world that surrounds them. When the fun is done, it's  time for baby animals to go to sleep. After all, every baby animal has  to rest.Full of action, sound effects, and patterned text, each  story features the adorable antics and accurate behavior of six wild  animal babies: tiger, elephant, giraffe, rhinoceros, panda, and gorilla.  This 64-page, beautifully designed story book is perfect for bedtime.</summary>
    <simplified:pwid>cd145f9d-5a32-d3b0-64b7-e6779260dea3</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250152596/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250152596/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Henry Holt &amp; Co</bib:publisherImprint>
    <dcterms:issued>2017-01-24</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0020037525</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2022-01-04T04:26:21+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0020037525"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0020037525/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>John F. Kennedy</title>
    <contributor opf:role="ill">
      <name>Fiorentino, Al</name>
    </contributor>
    <author>
      <name>Frisbee, Lucy Post</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Frisbee, Lucy Post" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Frisbee%2C%20Lucy%20Post/eng/Children"/>
    </author>
    <schema:Series name="History's All-stars">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="History's All-stars" href="https://qa-circulation.openebooks.us/USOEI/works/series/History%27s%20All-stars/eng/Children"/>
    </schema:Series>
    <summary type="html">When young Jack Kennedy's little sister gets tired of playing, Jack suggests a a sail. Within moments Jack is steering the small chip toward Osterville to say hello to Captain Manley. Under darkening clouds the two children head back home -- but they do not make it before a storm hits, threatening their lives. Is young Jack a good enough sailor to bring his sister home to safety?</summary>
    <simplified:pwid>f23dff14-b1c0-3bdb-d8c6-63e406dc9b2a</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781481430777/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781481430777/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Historical%20Fiction" label="Historical Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
    <bib:publisherImprint>Aladdin</bib:publisherImprint>
    <dcterms:issued>2015-01-13</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016692239</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2016-01-20T00:00:00Z</published>
    <updated>2022-01-04T04:13:12+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016692239"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016692239/open_book"/>
  </entry>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/440?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full" title="Title" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by" opds:activeFacet="true"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/440?entrypoint=Book&amp;order=author&amp;available=now&amp;collection=full" title="Author" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/440?entrypoint=Book&amp;order=added&amp;available=now&amp;collection=full" title="Recently Added" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link rel="next" href="https://qa-circulation.openebooks.us/USOEI/feed/440?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full&amp;key=%5B%22%5Cu1da2%5Cu4dd0%5Cu6086%5Cu3080%5Cu21e9%5Cu450d%5Cu0662%5Cu2f59%5Cu0089%5Cu4077%5Cu185b%5Cu4c4d%5Cu6050%5Cu0000%5Cu0000%22%2C+%22%5Cu19a5%5Cu4e53%5Cu2566%5Cu1310%5Cu3021%5Cu7d44%5Cu5ab2%5Cu0447%5Cu22a6%5Cu53c0%5Cu22c0%5Cu1dcb%5Cu76e6%5Cu0b70%5Cu0e00%5Cu0001%22%2C+3783%5D&amp;size=50"/>
  <link rel="start" href="https://qa-circulation.openebooks.us/USOEI/groups/" title="All Books"/>
  <simplified:breadcrumbs>
    <link title="All Books" href="https://qa-circulation.openebooks.us/USOEI/groups/"/>
    <link title="Book" href="https://qa-circulation.openebooks.us/USOEI/groups/?entrypoint=Book"/>
  </simplified:breadcrumbs>
  <link rel="http://opds-spec.org/auth/document" href="https://qa-circulation.openebooks.us/USOEI/authentication_document"/>
  <link rel="search" type="application/opensearchdescription+xml" href="https://qa-circulation.openebooks.us/USOEI/search/440?entrypoint=All&amp;order=title&amp;available=now&amp;collection=full"/>
  <link rel="http://opds-spec.org/shelf" type="application/atom+xml;profile=opds-catalog;kind=acquisition" href="https://qa-circulation.openebooks.us/USOEI/loans/"/>
  <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/"/>
  <link rel="http://opds-spec.org/crawlable" type="application/atom+xml;profile=opds-catalog;kind=acquisition" href="https://qa-circulation.openebooks.us/USOEI/lists/Books%20for%20integration%20testing/crawlable"/>
  <link href="http://openebooks.net/index.html" title="Home" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/about.html" title="About" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/faq.html" title="FAQs" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/resources.html" title="Resources" type="text/html" rel="related" role="navigation"/>
  <link href="https://openebooks.zendesk.com/" title="Help Center" type="text/html" rel="related" role="navigation"/>
  <link href="mailto:leonardrichardson@nypl.org" rel="help"/>
</feed>
`;

export default feed440;
