const relatedBooksHeartOfAChampion = `
<feed xmlns="http://www.w3.org/2005/Atom" xmlns:app="http://www.w3.org/2007/app" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:opds="http://opds-spec.org/2010/catalog" xmlns:opf="http://www.idpf.org/2007/opf" xmlns:drm="http://librarysimplified.org/terms/drm" xmlns:schema="http://schema.org/" xmlns:simplified="http://librarysimplified.org/terms/" xmlns:bibframe="http://bibframe.org/vocab/" xmlns:bib="http://bib.schema.org/" xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" xmlns:lcp="http://readium.org/lcp-specs/ns" simplified:entryPoint="http://schema.org/EBook">
  <id>https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610/related_books?entrypoint=Book</id>
  <title>Related Books</title>
  <updated>2022-01-05T20:43:00+00:00</updated>
  <link href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610/related_books?entrypoint=Book" rel="self"/>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Heart of a Champion</title>
    <author>
      <name>Deuker, Carl</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Deuker, Carl" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Deuker%2C%20Carl/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">Seth faces a strain on his friendship with Jimmy, who is both a baseball champion and something of an irresponsible fool, when Jimmy is kicked off the team.</summary>
    <simplified:pwid>6056c921-b26d-4534-af51-7c25e0ef9746</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316158411/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316158411/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
    <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
    <dcterms:issued>2009-05-30</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013217610</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Deuker%2C%20Carl/eng/All%2BAges%2CChildren%2CYoung%2BAdult" title="Deuker, Carl"/>
    <updated>2022-01-05T04:01:22+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013217610/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013217610"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013217610/open_book"/>
  </entry>
  <link rel="start" href="https://qa-circulation.openebooks.us/USOEI/groups/" title="All Books"/>
  <simplified:breadcrumbs>
    <link title="All Books" href="https://qa-circulation.openebooks.us/USOEI/groups/"/>
    <link title="Book" href="https://qa-circulation.openebooks.us/USOEI/groups/?entrypoint=Book"/>
  </simplified:breadcrumbs>
  <link rel="http://opds-spec.org/auth/document" href="https://qa-circulation.openebooks.us/USOEI/authentication_document"/>
  <link rel="search" type="application/opensearchdescription+xml" href="https://qa-circulation.openebooks.us/USOEI/search/"/>
  <link rel="http://opds-spec.org/shelf" type="application/atom+xml;profile=opds-catalog;kind=acquisition" href="https://qa-circulation.openebooks.us/USOEI/loans/"/>
  <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/"/>
  <link href="http://openebooks.net/index.html" title="Home" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/about.html" title="About" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/faq.html" title="FAQs" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/resources.html" title="Resources" type="text/html" rel="related" role="navigation"/>
  <link href="https://openebooks.zendesk.com/" title="Help Center" type="text/html" rel="related" role="navigation"/>
  <link href="mailto:leonardrichardson@nypl.org" rel="help"/>
</feed>
`;

export default relatedBooksHeartOfAChampion;
