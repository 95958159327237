export const feed614 = `
<feed xmlns="http://www.w3.org/2005/Atom" xmlns:app="http://www.w3.org/2007/app" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:opds="http://opds-spec.org/2010/catalog" xmlns:opf="http://www.idpf.org/2007/opf" xmlns:drm="http://librarysimplified.org/terms/drm" xmlns:schema="http://schema.org/" xmlns:simplified="http://librarysimplified.org/terms/" xmlns:bibframe="http://bibframe.org/vocab/" xmlns:bib="http://bib.schema.org/" xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" xmlns:lcp="http://readium.org/lcp-specs/ns" simplified:entryPoint="http://schema.org/EBook">
  <id>/USOEI/feed/614</id>
  <title>Home Page Grid (QA)</title>
  <updated>2023-10-08T17:20:56+00:00</updated>
  <link href="/USOEI/feed/614" rel="self"/>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Animal Crackers 1</title>
    <contributor opf:role="ill">
      <name>Holmes, Mike</name>
    </contributor>
    <author>
      <name>Sava, Scott Christian</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Sava, Scott Christian" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Sava%2C%20Scott%20Christian/eng/Children"/>
    </author>
    <schema:Series name="Animal Crackers">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Animal Crackers" href="https://qa-circulation.openebooks.us/USOEI/works/series/Animal%20Crackers/eng/Children"/>
    </schema:Series>
    <summary type="html">How does Uncle Bob do it? Owen couldn't figure it out.Buffalo Bob's Rootin' Tootin' Animal Circus is famous for its jump-roping giraffe, tangoing lion, and knife-throwing elephant. But they have to be fakes, right? Owen is determined to get to the bottom of this mystery. When he visits the circus he discovers that his uncle and, coincidentally, all of the animals, are missing. It can only be the work of Buffalo Bobâ€™s sideshow rival: Contorto.Owen stumbles upon the one thing that can save the day: Buffalo Bobâ€™s box of animal crackers. But these arenâ€™t any ordinary cookies: one bite and youâ€™re transformed into a circus animal! The audience is wowed by Owenâ€™s animal antics, but it will take more than a few carnival tricks to defeat Contorto and his pack of goons.</summary>
    <simplified:pwid>b15b4cf7-cb25-e600-53b6-b2256fb96401</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250157379/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250157379/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="7-8" label="7-8"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>First Second</bib:publisherImprint>
    <dcterms:issued>2017-03-21</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020215701/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0020215701</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020215701" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-11-14T00:00:00Z</published>
    <updated>2023-10-08T17:16:59+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020215701/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020215701/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0020215701"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0020215701/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Astro Girl</title>
    <contributor opf:role="ill">
      <name>Wilson-Max, Ken</name>
    </contributor>
    <author>
      <name>Wilson-Max, Ken</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Wilson-Max, Ken" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Wilson-Max%2C%20Ken/eng/Children"/>
    </author>
    <summary type="html">The stars are the limit for a little girl who acts out her wish to be an astronaut â€” inspired by a very special person.Astrid has loved the stars and space for as long as she can remember. â€œI want to be an astronaut!â€ she says to everyone who will listen. While her mama is away, Astrid and her papa have fun acting out the challenges an astronaut faces on a space mission â€” like being in zero gravity (â€œI can do that all day long!â€ she says), eating food from a kind of tube, and doing science experiments with the help of cookie sheets. When at last itâ€™s time to meet Mama at the air base, Astrid wears her favorite space T-shirt to greet her. But where exactly has Mama been? Channeling a sense of childlike delight, Ken Wilson-Max brings space travel up close for young readers and offers an inspiring ending.</summary>
    <simplified:pwid>1491a7a0-58d4-1aa6-1fdf-24f925f7d699</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221725/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221725/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2021-01-26</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027671700/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027671700</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027671700" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:50+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027671700/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027671700/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027671700"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027671700/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Backyard Animal Show</title>
    <contributor opf:role="ill">
      <name>Watson, Jesse Joshua</name>
    </contributor>
    <author>
      <name>Draper, Sharon M.</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Draper, Sharon M." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Draper%2C%20Sharon%20M./eng/Children"/>
    </author>
    <schema:Series name="Clubhouse Mysteries">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Clubhouse Mysteries" href="https://qa-circulation.openebooks.us/USOEI/works/series/Clubhouse%20Mysteries/eng/Children"/>
    </schema:Series>
    <summary type="html">In this fifth book of the classic chapter book series by award-winning author Sharon M. Draper, four boys who call themselves the Black Dinosaurs get up close and personal with the pets of the neighborhood.After witnessing a tragic accident in which a doe is killed, the boys in the Clubhouse Mysteries gang are able to rescue her orphaned fawn. But they realize that nearby construction means the destruction of more local animalsâ€™ homes, so Ziggy, Rashawn, Jerome, and Rico decide to take up the cause and host a backyard animal show to raise money for the local wildlife rescue center.  Dino, the adorable rescued fawn, will be the star attraction. And given that the boys have already tackled caring for a deer, managing their friendsâ€™ dogs, cats, and hamsters should be easy&amp;hellip;right?</summary>
    <simplified:pwid>eab52be5-9b41-9549-3e2d-e1761f344c47</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442450240/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442450240/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Mystery" label="Mystery"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
    <bib:publisherImprint>Aladdin</bib:publisherImprint>
    <dcterms:issued>2012-07-24</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686186/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016686186</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686186" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2016-01-18T00:00:00Z</published>
    <updated>2023-10-08T16:54:19+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686186/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686186/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016686186"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016686186/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Black Friend</title>
    <author>
      <name>Joseph, Frederick</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Joseph, Frederick" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Joseph%2C%20Frederick/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">Writing from the perspective of a friend, Frederick Joseph offers candid reflections on his own experiences with racism and conversations with prominent artists and activists about theirsâ€”creating an essential read for white people who are committed anti-racists and those newly come to the cause of racial justice.â€œWe donâ€™t see color.â€ â€œI didnâ€™t know Black people liked Star Wars!â€ â€œWhat hood are you from?â€ For Frederick Joseph, life as a transfer student in a largely white high school was full of wince-worthy moments that he often simply let go. As he grew older, however, he saw these as missed opportunities not only to stand up for himself, but to spread awareness to those white people who didnâ€™t see the negative impact they were having. Speaking directly to the reader, The Black Friend calls up race-related anecdotes from the authorâ€™s past, weaving in his thoughts on why they were hurtful and how he might handle things differently now. Each chapter features the voice of at least one artist or activist, including Angie Thomas, author of The Hate U Give; April Reign, creator of #OscarsSoWhite; Jemele Hill, sports journalist and podcast host; and eleven others. Touching on everything from cultural appropriation to power dynamics, â€œreverse racismâ€ to white privilege, microaggressions to the tragic results of overt racism, this book serves as conversation starter, tool kit, and invaluable window into the life of a former â€œtoken Black kidâ€ who now presents himself as the friend many readers need. Backmatter includes an encyclopedia of racism, providing details on relevant historical events, terminology, and more.</summary>
    <simplified:pwid>8e3d4fff-3c45-b484-3d80-ab1b3d88e0f6</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536218220/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536218220/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Life%20Strategies" label="Life Strategies"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Political%20Science" label="Political Science"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-12-01</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026873771/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026873771</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026873771" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:33+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026873771/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026873771/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026873771"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026873771/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Brontorina</title>
    <contributor opf:role="ill">
      <name>Cecil, Randy</name>
    </contributor>
    <author>
      <name>Howe, James</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Howe, James" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Howe%2C%20James/eng/Children"/>
    </author>
    <simplified:pwid>fdf2599e-3546-bb7d-55c8-21fc2213d4b8</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221565/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221565/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="7-8" label="7-8"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2021-02-23</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027839724/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027839724</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027839724" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:50+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027839724/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027839724/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027839724"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027839724/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Bunny Bus</title>
    <contributor opf:role="ill">
      <name>Withrow, Lesley Breen</name>
    </contributor>
    <author>
      <name>Paquette, Ammi-Joan</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Paquette, Ammi-Joan" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Paquette%2C%20Ammi-Joan/eng/Children"/>
    </author>
    <summary type="html">Bunny Bus is a picture book perfect for those who love cute animals and vehiclesâ€”ideal for springtime and Easter. Hop, hop, hop, STOP!Are you ready to climb aboard the Bunny Bus? Up one hill. Down another. Come join the eager troop of animal friends as they bounce along to the Easter Parade.</summary>
    <simplified:pwid>c677ad93-f664-d5bd-5207-2a97c594b147</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466896710/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466896710/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Farrar Straus &amp; Giroux</bib:publisherImprint>
    <dcterms:issued>2017-01-17</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019999320/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0019999320</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019999320" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2023-10-08T17:16:20+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019999320/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019999320/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0019999320"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0019999320/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The City Jungle</title>
    <contributor opf:role="trl">
      <name>Chambers, Whittaker</name>
    </contributor>
    <author>
      <name>Salten, Felix</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Salten, Felix" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Salten%2C%20Felix/eng/Children"/>
    </author>
    <schema:Series name="Bambi's Classic Animal Tales">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Bambi's Classic Animal Tales" href="https://qa-circulation.openebooks.us/USOEI/works/series/Bambi%27s%20Classic%20Animal%20Tales/eng/Children"/>
    </schema:Series>
    <summary type="html">Get to know the lives and longings of animals in a city zoo in this time-honored tale from the author of Bambi.The animals of the city zoo miss their homes. While they appreciate the company of one another, they have a fierce longing to be free of the daily visitors, the city soundsâ€”and most of all, the bars to their cages. Vasta the mouse is the only animal who is not behind bars. She uses her freedom to travel from cage to cage, visiting Yppa the orangutan and her young son Tikki, Hella the proud lioness and her two cubs, Mino the crazy fox, Pardinos the friendly elephant, and Hallo the tame wolf. The zookeepers and visitors have no idea what life is really like in this city jungle, but Felix Saltenâ€™s depiction of these animalsâ€™ stories is brought vividly to life in this beautiful repackage.</summary>
    <simplified:pwid>0e9045f0-9b14-25f8-42e2-16cec5a42871</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442487536/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442487536/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Classics" label="Classics"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
    <bib:publisherImprint>Aladdin</bib:publisherImprint>
    <dcterms:issued>2014-10-14</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016691823/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016691823</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016691823" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2016-01-18T00:00:00Z</published>
    <updated>2023-10-08T17:04:49+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016691823/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016691823/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016691823"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016691823/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Coronavirus</title>
    <contributor opf:role="ill">
      <name>Scheffler, Axel</name>
    </contributor>
    <author>
      <name>Jenner, Elizabeth</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Jenner, Elizabeth" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Jenner%2C%20Elizabeth/eng/Children"/>
    </author>
    <author>
      <name>Wilson, Kate</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Wilson, Kate" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Wilson%2C%20Kate/eng/Children"/>
    </author>
    <author>
      <name>Roberts, Nia</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Roberts, Nia" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Roberts%2C%20Nia/eng/Children"/>
    </author>
    <summary type="html">â€œAn elegant, effective work. Download this now, read it immediately, share it with everyone you know.â€ â€”School Library Journal (starred review)This informative and accessible guide for young readers defines the coronavirus, explains why everyday routines have been disrupted, and lays out how everyone can do their part to help. With child-appropriate answers and explanations, the book addresses key questions, like: How do you catch coronavirus and what happens if you have it? Why are people so worried about it? Is there a cure? Why do we have to stay home? What can I do to help? And what happens next?</summary>
    <simplified:pwid>a503ecee-0c27-13a5-205c-38e7d613478c</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536219210/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536219210/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Health%20%26%20Diet" label="Health &amp; Diet"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Life%20Strategies" label="Life Strategies"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-04-15</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026653189/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026653189</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026653189" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:31+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026653189/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026653189/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026653189"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026653189/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Daisy the Deer</title>
    <author>
      <name>Small, Lily</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Small, Lily" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Small%2C%20Lily/eng/Children"/>
    </author>
    <schema:Series name="Fairy Animals of Misty Wood">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Fairy Animals of Misty Wood" href="https://qa-circulation.openebooks.us/USOEI/works/series/Fairy%20Animals%20of%20Misty%20Wood/eng/Children"/>
    </schema:Series>
    <summary type="html">Enter an enchanted world of fairy animal friends!Daisy the Deer  loves flying around in the moonlight, whispering happy dreams into the  ears of the sleeping fairy animals. But one night she meets a little  hedgehog who is too scared to go to sleep. Can Daisy persuade him that  there's nothing to be afraid of in Misty Wood?</summary>
    <simplified:pwid>94482528-4bef-094f-283c-5b77e1b74b6e</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781627797399/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781627797399/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Henry Holt &amp; Co</bib:publisherImprint>
    <dcterms:issued>2017-02-28</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019333595/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0019333595</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019333595" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2023-10-08T17:14:50+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019333595/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019333595/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0019333595"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0019333595/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Detector Dogs, Dynamite Dolphins, and More Animals With Super Sensory Powers</title>
    <contributor opf:role="ill">
      <name>Duncan, Daniel</name>
    </contributor>
    <author>
      <name>Couch, Christina</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Couch, Christina" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Couch%2C%20Christina/eng/Children"/>
    </author>
    <author>
      <name>Giaimo, Cara</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Giaimo, Cara" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Giaimo%2C%20Cara/eng/Children"/>
    </author>
    <schema:Series name="Extraordinary Animals">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Extraordinary Animals" href="https://qa-circulation.openebooks.us/USOEI/works/series/Extraordinary%20Animals/eng/Children"/>
    </schema:Series>
    <summary type="html">Introduces incredible animals from around the world that help their human co-workers tackle real-world problems such as pollution and global warning. Simultaneous and eBook. Illustrations.</summary>
    <simplified:pwid>454f334d-95da-9d77-cc0f-bf8438f4e587</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536227109/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536227109/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Nature" label="Nature"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Science" label="Science"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Mit Kids Pr</bib:publisherImprint>
    <dcterms:issued>2022-09-13</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0029449107/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0029449107</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0029449107" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:53+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0029449107/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0029449107/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0029449107"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0029449107/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Dollar Kids</title>
    <contributor opf:role="ill">
      <name>Andrews, Ryan</name>
    </contributor>
    <author>
      <name>Jacobson, Jennifer Richard</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Jacobson, Jennifer Richard" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Jacobson%2C%20Jennifer%20Richard/eng/Children"/>
    </author>
    <summary type="html">When a family buys a house in a struggling town for just one dollar, theyâ€™re hoping to start over â€” but have they traded one set of problems for another?Twelve-year-old Lowen Grover, a budding comic-book artist, is still reeling from the shooting death of his friend Abe when he stumbles across an article about a former mill town giving away homes for just one dollar. It not only seems like the perfect escape from Flintlock and all of the awful memories associated with the city, but an opportunity for his mum to run her very own business. Fortunately, his family is willing to give it a try. But is the Dollar Program too good to be true? The homes are in horrible shape, and the locals are less than welcoming. Will Millville and the dollar house be the answer to the Groversâ€™ troubles? Or will they find theyâ€™ve traded one set of problems for another? From the author of Small as an Elephant and Paper Things comes a heart-tugging novel about guilt and grief, family and friendship, and, above all, community.</summary>
    <simplified:pwid>384191db-765c-b22e-fba6-1a38581f247c</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780763699857/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780763699857/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2018-08-07</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0023594166/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0023594166</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0023594166" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:27+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0023594166/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0023594166/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0023594166"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0023594166/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Dolphin Baby!</title>
    <contributor opf:role="ill">
      <name>Granstrom, Brita</name>
    </contributor>
    <author>
      <name>Davies, Nicola</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Davies, Nicola" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Davies%2C%20Nicola/eng/Children"/>
    </author>
    <schema:Series name="Read and Wonder">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Read and Wonder" href="https://qa-circulation.openebooks.us/USOEI/works/series/Read%20and%20Wonder/eng/Children"/>
    </schema:Series>
    <simplified:pwid>8d833d09-c91d-b162-87fb-778d0512cabc</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221589/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221589/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Nature" label="Nature"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2021-07-06</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028332464/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0028332464</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028332464" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:52+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028332464/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028332464/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0028332464"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0028332464/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Dreams of a Dancing Horse</title>
    <author>
      <name>Mackall, Dandi Daley</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Mackall, Dandi Daley" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Mackall%2C%20Dandi%20Daley/eng/Children"/>
    </author>
    <schema:Series name="Animal Tales">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Animal Tales" href="https://qa-circulation.openebooks.us/USOEI/works/series/Animal%20Tales/eng/Children"/>
    </schema:Series>
    <summary type="html">Unable to resist dancing when he hears music, even if it means overturning the plow and wreaking havoc, Fred the horse is banished from the farm and embarks on a journey to find a home where his dancing is welcome, in a latest entry in the series that includes The Pup Who Cried Wolf. Simultaneous.</summary>
    <simplified:pwid>ef5922d0-814a-3926-da95-e296e78ecf74</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781599907734/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781599907734/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Bloomsbury USA</dcterms:publisher>
    <bib:publisherImprint>Bloomsbury Childrens Books</bib:publisherImprint>
    <dcterms:issued>2011-10-11</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0010163964/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0010163964</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0010163964" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2015-09-01T00:00:00Z</published>
    <updated>2023-10-08T16:56:09+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0010163964/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="10000" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0010163964/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0010163964"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0010163964/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Farmer Duck</title>
    <contributor opf:role="ill">
      <name>Oxenbury, Helen</name>
    </contributor>
    <author>
      <name>Waddell, Martin</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Waddell, Martin" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Waddell%2C%20Martin/eng/Children"/>
    </author>
    <summary type="html">A hardworking duck is rescued from life with a lazy old farmer in this classic tale of justice.Farmer Duck isn't your average duck. This duck cooks and cleans, tends the fields, and cares for the other animals on the farmâ€”all because the owner of the farm is too lazy to do these things himself. But when Farmer Duck finally collapses from exhaustion, the farmyard animals come to the rescue with a simple but heroic plan.</summary>
    <simplified:pwid>ae044516-1069-a727-9520-1ded66483b5d</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220841/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220841/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-11-03</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063525/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027063525</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063525" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:49+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063525/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063525/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027063525"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027063525/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Good Night, Baby Animals You've Had a Busy Day</title>
    <contributor opf:role="ill">
      <name>Watkins, Laura</name>
    </contributor>
    <author>
      <name>Winnick, Karen B.</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Winnick, Karen B." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Winnick%2C%20Karen%20B./eng/Children"/>
    </author>
    <summary type="html">Whether they're dashing, splashing, munching, or crunching, baby animals  are ever-exploring! At home in their natural habitats, baby animals  learn about the world that surrounds them. When the fun is done, it's  time for baby animals to go to sleep. After all, every baby animal has  to rest.Full of action, sound effects, and patterned text, each  story features the adorable antics and accurate behavior of six wild  animal babies: tiger, elephant, giraffe, rhinoceros, panda, and gorilla.  This 64-page, beautifully designed story book is perfect for bedtime.</summary>
    <simplified:pwid>cd145f9d-5a32-d3b0-64b7-e6779260dea3</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250152596/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250152596/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Henry Holt &amp; Co</bib:publisherImprint>
    <dcterms:issued>2017-01-24</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0020037525</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2023-10-08T17:16:22+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0020037525"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0020037525/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>How Do You Wokka-wokka?</title>
    <author>
      <name>Cecil, Randy</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Cecil, Randy" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Cecil%2C%20Randy/eng/Children"/>
    </author>
    <author>
      <name>Bluemle, Elizabeth</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Bluemle, Elizabeth" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Bluemle%2C%20Elizabeth/eng/Children"/>
    </author>
    <summary type="html">A young boy who likes to "wokka-wokka, shimmy-shake, and shocka-shocka" gathers his neighbors together for a surprise celebration.</summary>
    <simplified:pwid>03cc2bd7-08c5-0270-d6a1-b22c447cb8d7</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221152/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221152/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2021-05-04</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027968752/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027968752</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027968752" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:52+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027968752/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027968752/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027968752"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027968752/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>How to Heal a Broken Wing</title>
    <contributor opf:role="ill">
      <name>Graham, Bob</name>
    </contributor>
    <author>
      <name>Graham, Bob</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Graham, Bob" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Graham%2C%20Bob/eng/Children"/>
    </author>
    <simplified:pwid>8e5a5267-df91-7417-2112-0d68ed948109</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220926/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220926/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-11-03</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063535/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027063535</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063535" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:34+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063535/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063535/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027063535"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027063535/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>If Sharks Disappeared</title>
    <contributor opf:role="ill">
      <name>Williams, Lily</name>
    </contributor>
    <author>
      <name>Williams, Lily</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Williams, Lily" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Williams%2C%20Lily/eng/Children"/>
    </author>
    <schema:Series name="If Animals Disappeared">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="If Animals Disappeared" href="https://qa-circulation.openebooks.us/USOEI/works/series/If%20Animals%20Disappeared/eng/Children"/>
    </schema:Series>
    <summary type="html">A healthy ocean is home to many different kinds of animals. They can be big, like a whale, tiny, like a shrimp, and even scary, like a shark.   Even though sharks can be scary, we need them to keep the oceans  healthy. Unfortunately, due to overfishing, many shark species are in  danger of extinction, and that can cause big problems in the oceans and  even on land.  What would happen if this continued and sharks disappeared completely?Artist  Lily Williams explores how the disappearance would affect other animals across the whole  planet in this clever book about the importance of keeping sharks, and  our oceans, healthy.</summary>
    <simplified:pwid>23533c4c-b54b-ee2c-86a3-ceed715575d6</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250171382/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250171382/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Nature" label="Nature"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Roaring Brook</bib:publisherImprint>
    <dcterms:issued>2017-05-23</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020633122/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0020633122</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020633122" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2023-10-08T17:16:47+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020633122/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020633122/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0020633122"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0020633122/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Imagina/ Imagine</title>
    <contributor opf:role="ill">
      <name>Castillo, Lauren</name>
    </contributor>
    <author>
      <name>Herrera, Juan Felipe</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Herrera, Juan Felipe" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Herrera%2C%20Juan%20Felipe/eng/Children"/>
    </author>
    <simplified:pwid>04de2b77-cf32-e346-3853-ae37d0de67b7</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536222159/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536222159/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Biography%20%26%20Memoir" label="Biography &amp; Memoir"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-10-06</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988248/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026988248</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988248" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:48+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988248/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988248/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026988248"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026988248/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Imagine</title>
    <contributor opf:role="trl">
      <name>LÃ¡zaro, Georgina</name>
    </contributor>
    <contributor opf:role="ill">
      <name>Castillo, Lauren</name>
    </contributor>
    <author>
      <name>Herrera, Juan Felipe</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Herrera, Juan Felipe" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Herrera%2C%20Juan%20Felipe/eng/Children"/>
    </author>
    <summary type="html">A buoyant, breathtaking poem from Juan Felipe Herrera â€” brilliantly illustrated by Caldecott Honoree Lauren Castillo â€” speaks to every dreaming heart.Have you ever imagined what you might be when you grow up? When he was very young, Juan Felipe Herrera picked chamomile flowers in windy fields and let tadpoles swim across his hands in a creek. He slept outside and learned to say good-bye to his amiguitos each time his family moved to a new town. He went to school and taught himself to read and write English and filled paper pads with rivers of ink as he walked down the street after school. And when he grew up, he became the United States Poet Laureate and read his poems aloud on the steps of the Library of Congress. If he could do all of that . . . what could you do? With this illustrated poem of endless possibility, Juan Felipe Herrera and Lauren Castillo breathe magic into the hopes and dreams of readers searching for their place in life.</summary>
    <simplified:pwid>0caef6cb-58fc-6f48-fc07-c91a895890b2</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220575/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220575/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Biography%20%26%20Memoir" label="Biography &amp; Memoir"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-10-06</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988234/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026988234</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988234" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:34+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988234/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988234/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026988234"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026988234/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>In a Monday Mood</title>
    <author>
      <name>Reynolds, Peter H.</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Reynolds, Peter H." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Reynolds%2C%20Peter%20H./eng/Children"/>
    </author>
    <author>
      <name>McDonald, Megan</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="McDonald, Megan" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/McDonald%2C%20Megan/eng/Children"/>
    </author>
    <schema:Series name="Judy Moody">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Judy Moody" href="https://qa-circulation.openebooks.us/USOEI/works/series/Judy%20Moody/eng/Children"/>
    </schema:Series>
    <summary type="html">For anyone who has ever had the Sunday night blues, Judy declares that every day can be a holiday if you just find something to celebrate. Happy National Eat Ice Cream for Breakfast Day, anyone?Crumbs! Why canâ€™t every day be Saturday?Judy Moody is Monday-morning mopey. Another week in her same-old seat at her same-old desk in her same-old school. Even worse, there arenâ€™t any days off from school for ages. But when she steps into Class 3T, Judyâ€™s Monday frown turns upside down. Pop! Pop! Pop-pop-pop! Mr. Todd is making Monday special by celebrating Bubble Wrap Appreciation Day (no lie)! This gives Judy an idea that just-might-maybe turn her week around: why not make every day of the week a holiday? But after she and her friends come up with a weekâ€™s worth of wacky celebrations, from feeding ninja squirrels to honoring National Sneak Some Zucchini Onto Your Neighborâ€™s Porch Day, will the weekend be one big letdown?</summary>
    <simplified:pwid>31d7cd4f-c9b4-f570-31be-d3b5ccad4b9e</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536222289/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536222289/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Humorous%20Fiction" label="Humorous Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Religious%20Fiction" label="Religious Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2021-09-14</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859727/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027859727</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859727" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:50+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859727/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859727/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027859727"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027859727/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>It's Our Garden</title>
    <contributor opf:role="ill">
      <name>Ancona, George</name>
    </contributor>
    <author>
      <name>Ancona, George</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Ancona, George" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Ancona%2C%20George/eng/Children"/>
    </author>
    <summary type="html">Presents a portrait of a Santa Fe community garden, revealing how students and other citizens work together to select and grow annual plants, create compost, release butterflies, harvest edibles, and protect garden beds for the winter.</summary>
    <simplified:pwid>011bd029-89d0-46e9-9fa6-88eb399fccf9</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221602/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221602/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Gardening" label="Gardening"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Health%20%26%20Diet" label="Health &amp; Diet"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Education" label="Education"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2021-07-06</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028332466/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0028332466</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028332466" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:52+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028332466/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028332466/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0028332466"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0028332466/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Jabari Jumps</title>
    <contributor opf:role="ill">
      <name>Cornwall, Gaia</name>
    </contributor>
    <author>
      <name>Cornwall, Gaia</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Cornwall, Gaia" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Cornwall%2C%20Gaia/eng/Children"/>
    </author>
    <simplified:pwid>062fedae-43f5-8402-f01a-fbbea2b9da38</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220674/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220674/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-10-06</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988246/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026988246</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988246" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:48+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988246/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988246/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026988246"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026988246/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Mangoes, Mischief, and Tales of Friendship</title>
    <contributor opf:role="ill">
      <name>Krishnaswamy, Uma</name>
    </contributor>
    <author>
      <name>Soundar, Chitra</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Soundar, Chitra" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Soundar%2C%20Chitra/eng/Children"/>
    </author>
    <schema:Series name="Chitra Soundar's Stories from India">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Chitra Soundar's Stories from India" href="https://qa-circulation.openebooks.us/USOEI/works/series/Chitra%20Soundar%27s%20Stories%20from%20India/eng/Children"/>
    </schema:Series>
    <summary type="html">â€œSoundarâ€™s energetic prose and wisecracking dialogue sparkle. . . . The adventures of Prince Veera and Suku will surely captivate.â€ â€”Shelf Awareness (starred review)Being a wise and just ruler is no easy task. Thatâ€™s what Prince Veera discovers when he and his best friend, Suku, are given the opportunity to preside over the court of his father, King Bheema. How should they handle the case of the greedy merchant who wishes to charge people for enjoying the smells of his sweets? Can they prove that an innocent man cannot possibly spread bad luck? Will Prince Veera and Suku be able to settle the dispute between a man and his neighbor to whom he sells a wellâ€”but not the water in it? Or solve the mystery of the jewels that have turned into pickles? Illustrated by Uma Krishnaswamy, these eight stories from Chitra Soundar, inspired by Indian folktales, task Veera and Suku with outwitting the kingdomâ€™s greediest, wiliest subjects. Are the two clever boys up to the challenge?</summary>
    <simplified:pwid>6ada98ed-d2cf-f87d-e4c5-16d62af5f904</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221060/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221060/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Folklore" label="Folklore"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Humorous%20Fiction" label="Humorous Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2021-01-05</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027386819/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027386819</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027386819" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:49+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027386819/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027386819/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027386819"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027386819/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Mia the Mouse</title>
    <author>
      <name>Small, Lily</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Small, Lily" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Small%2C%20Lily/eng/Children"/>
    </author>
    <schema:Series name="Fairy Animals of Misty Wood">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Fairy Animals of Misty Wood" href="https://qa-circulation.openebooks.us/USOEI/works/series/Fairy%20Animals%20of%20Misty%20Wood/eng/Children"/>
    </schema:Series>
    <summary type="html">Mia is in the middle of telling a story to her sick grandmother when her mother asks her to run an errand, but, distracted by what may come next in the story, all Mia can remember is that she is to fetch something that starts with "B". Includes activities.</summary>
    <simplified:pwid>bd4d7c64-61d1-6e58-6c0f-d81b1b1eddbd</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781627793636/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781627793636/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Henry Holt &amp; Co</bib:publisherImprint>
    <dcterms:issued>2015-08-25</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016503444/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016503444</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016503444" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2023-10-08T17:15:15+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016503444/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016503444/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016503444"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016503444/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>North</title>
    <contributor opf:role="ill">
      <name>Benson, Patrick</name>
    </contributor>
    <author>
      <name>Dowson, Nick</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Dowson, Nick" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Dowson%2C%20Nick/eng/Children"/>
    </author>
    <summary type="html">Presents a tribute to the migrations of millions of creatures to the Arctic, including depictions of the cyclical journeys of gray whales, caribou, and wolves.</summary>
    <simplified:pwid>a0cb9098-214e-7348-0dff-c43c9e338d9c</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220933/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220933/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Nature" label="Nature"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="7-8" label="7-8"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-12-01</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027122272/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027122272</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027122272" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:35+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027122272/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027122272/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027122272"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027122272/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Paddy the Puppy</title>
    <author>
      <name>Small, Lily</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Small, Lily" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Small%2C%20Lily/eng/Children"/>
    </author>
    <schema:Series name="Fairy Animals of Misty Wood">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Fairy Animals of Misty Wood" href="https://qa-circulation.openebooks.us/USOEI/works/series/Fairy%20Animals%20of%20Misty%20Wood/eng/Children"/>
    </schema:Series>
    <summary type="html">Paddy the Pollen Puppy is so excited about today being his birthday that he causes all sorts of trouble. Includes activities.</summary>
    <simplified:pwid>926db75b-31f5-c85f-2414-d6653fa89612</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781627793629/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781627793629/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Henry Holt &amp; Co</bib:publisherImprint>
    <dcterms:issued>2015-08-25</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016503442/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016503442</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016503442" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2023-10-08T17:15:15+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016503442/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016503442/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016503442"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016503442/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>A Piglet Called Truffle</title>
    <contributor opf:role="ill">
      <name>Snowdon, Ellie</name>
    </contributor>
    <author>
      <name>Peters, Helen</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Peters, Helen" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Peters%2C%20Helen/eng/Children"/>
    </author>
    <schema:Series name="Jasmine Green Rescues">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Jasmine Green Rescues" href="https://qa-circulation.openebooks.us/USOEI/works/series/Jasmine%20Green%20Rescues/eng/Children"/>
    </schema:Series>
    <summary type="html">Meet Jasmine Green â€” an aspiring veterinarian who adores animals! Can her kindness and know-how save a piglet in trouble in this delightful series debut? Jasmine Green loves animals. Her mother is a veterinarian. Her father is a farmer. And her brother and sister are . . . well, theyâ€™re mostly annoying. But being in the Green family means seeing and taking care of animals all the time. While helping her mom on a house call, Jasmine visits a new litter of piglets and discovers a forgotten runt hidden underneath its brothers and sisters. Poor little piglet. It is so tiny that it canâ€™t even drink! Its owner refuses to rescue it. So it is up to Jasmine to save the pig . . . secretly. What will happen if anyone finds out? Author Helen Peters and illustrator Ellie Snowdon introduce the irresistible pair of clever, caring Jasmine and lovable Truffle, while capturing the beauty and bustle of a family farm. A kind of James Herriot for a new generation, this first book in the Jasmine Green series is for anyone who loves helping animals.</summary>
    <simplified:pwid>3d3088fc-f342-4e6c-2a9a-67137668d6e9</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536211955/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536211955/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="7-8" label="7-8"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Walker Books Us</bib:publisherImprint>
    <dcterms:issued>2020-03-17</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0025779949/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0025779949</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0025779949" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:29+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0025779949/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0025779949/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0025779949"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0025779949/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Princess in Black and the Case of the Coronavirus</title>
    <contributor opf:role="ill">
      <name>Pham, Leuyen</name>
    </contributor>
    <author>
      <name>Hale, Dean</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hale, Dean" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hale%2C%20Dean/eng/Children"/>
    </author>
    <author>
      <name>Hale, Shannon</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hale, Shannon" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hale%2C%20Shannon/eng/Children"/>
    </author>
    <summary type="html">Available as a free digital booklet, here is a child-friendly coronavirus public service announcement starring the Princess in Black and her friends from the New York Times best-selling series!In the words of author Shannon Hale: â€œLeUyen, Dean, and I are all parents self-isolating at home with our children. The anxiety and distancing are difficult enough for our older kids, but we know that younger kids might be having an even harder time. We hoped that it would help if a familiar book friend like the Princess in Black talked them through it. Even the Princess in Black is staying home! Even Princess Sneezewort had to cancel playdates! LeUyen had the idea of creating a short comic to download and share widely so that caregivers could have an extra tool for talking to children. Our goal is both to help kids understand whatâ€™s going on and to help them feel less alone.â€</summary>
    <simplified:pwid>220d2fa2-1fcb-0f4d-520c-a47d62fbb4c8</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536219524/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536219524/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-06-01</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026729489/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026729489</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026729489" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:32+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026729489/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026729489/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026729489"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026729489/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Pup Who Cried Wolf</title>
    <author>
      <name>Kurtz, Chris</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Kurtz, Chris" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Kurtz%2C%20Chris/eng/Children"/>
    </author>
    <schema:Series name="Animal Tales">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Animal Tales" href="https://qa-circulation.openebooks.us/USOEI/works/series/Animal%20Tales/eng/Children"/>
    </schema:Series>
    <summary type="html">Lobo is a tiny Chihuahua with a big dream-to join a wolf pack. When Lobo's owner takes her pets on vacation to Yellowstone National Park, Lobo thinks he'll finally have the opportunity to find his wolf-brothers. But he doesn't count on a strict park rule that will keep him on a leash! With the help of his endearingly quirky family-a smart Spanish-speaking parrot named Glory and a wisecracking white rat named Heckles-Lobo finally slips his leash and gets a chance to run with the wolves. But is the wild really where a feisty little five-pound pup belongs?</summary>
    <simplified:pwid>ceecfeb7-9f16-652b-af1a-69d9a5497bfe</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781599908861/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781599908861/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="7-8" label="7-8"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Bloomsbury USA</dcterms:publisher>
    <bib:publisherImprint>Bloomsbury Childrens Books</bib:publisherImprint>
    <dcterms:issued>2011-10-01</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0010163937/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0010163937</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0010163937" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2016-01-15T00:00:00Z</published>
    <updated>2023-10-08T17:00:46+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0010163937/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0010163937/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0010163937"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0010163937/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Rain Before Rainbows</title>
    <contributor opf:role="ill">
      <name>Litchfield, David</name>
    </contributor>
    <author>
      <name>Prasadam-Halls, Smriti</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Prasadam-Halls, Smriti" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Prasadam-Halls%2C%20Smriti/eng/Children"/>
    </author>
    <simplified:pwid>61329cec-14f9-9d12-f5de-0c2258848af2</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536219784/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536219784/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-08-17</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026882084/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026882084</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026882084" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:33+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026882084/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026882084/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026882084"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026882084/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Rural Voices</title>
    <contributor opf:role="edt">
      <name>Carpenter, Nora Shalaway</name>
    </contributor>
    <contributor opf:role="asn">
      <name>Bowles, David</name>
    </contributor>
    <contributor opf:role="asn">
      <name>Bruchac, Joseph</name>
    </contributor>
    <contributor opf:role="asn">
      <name>Bybee, Veeda</name>
    </contributor>
    <contributor opf:role="asn">
      <name>Edwards, Shanna</name>
    </contributor>
    <simplified:pwid>aaa6c114-5cba-f017-d6f0-35a77692ea05</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536216110/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536216110/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Short%20Stories" label="Short Stories"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Comics%20%26%20Graphic%20Novels" label="Comics &amp; Graphic Novels"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-10-13</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425180/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026425180</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425180" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:30+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425180/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425180/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026425180"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026425180/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Schomburg</title>
    <contributor opf:role="ill">
      <name>Velasquez, Eric</name>
    </contributor>
    <author>
      <name>Weatherford, Carole Boston</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Weatherford, Carole Boston" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Weatherford%2C%20Carole%20Boston/eng/Children"/>
    </author>
    <summary type="html">â€œA must-read for a deeper understanding of a well-connected genius who enriched the cultural road map for African Americans and books about them.â€ â€” Kirkus Reviews (starred review)Amid the scholars, poets, authors, and artists of the Harlem Renaissance stood an Afroâ€“Puerto Rican named Arturo Schomburg. This law clerkâ€™s passion was to collect books, letters, music, and art from Africa and the African diaspora and bring to light the achievements of people of African descent through the ages. A century later, his groundbreaking collection, known as the Schomburg Center for Research in Black Culture, has become a beacon to scholars all over the world. In luminous paintings and arresting poems, two of childrenâ€™s literatureâ€™s top African-American scholars track Arturo Schomburgâ€™s quest to correct history.</summary>
    <simplified:pwid>974ff85e-80b3-a2ad-d5c8-3a5903da618e</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220636/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536220636/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Biography%20%26%20Memoir" label="Biography &amp; Memoir"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-10-06</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988240/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026988240</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988240" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:34+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988240/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026988240/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026988240"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026988240/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Somebody Give This Heart a Pen</title>
    <author>
      <name>Thakur, Sophia</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Thakur, Sophia" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Thakur%2C%20Sophia/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">A coming-of-age debut collection by the acclaimed spoken-word performance poet explores issues ranging from identity and perseverance to relationships and loss, in a volume that draws on the authorâ€™s experiences as a mixed-race woman in a lonely and complicated world. Simultaneous eBook.</summary>
    <simplified:pwid>7e68abee-d58c-ad7b-adb3-60a85e6f03f1</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536216165/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536216165/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Life%20Strategies" label="Life Strategies"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-09-08</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425188/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026425188</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425188" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:30+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425188/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425188/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026425188"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026425188/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Sona Sharma, Very Best Big Sister?</title>
    <author>
      <name>Khatun, Jen</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Khatun, Jen" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Khatun%2C%20Jen/eng/Children"/>
    </author>
    <author>
      <name>Soundar, Chitra</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Soundar, Chitra" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Soundar%2C%20Chitra/eng/Children"/>
    </author>
    <schema:Series name="Sona Sharma">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Sona Sharma" href="https://qa-circulation.openebooks.us/USOEI/works/series/Sona%20Sharma/eng/Children"/>
    </schema:Series>
    <summary type="html">Sona is not sure how she feels about a new baby in the family until the baby-naming ceremony, during which she is determined to find the best name for her new brother or sister. Simultaneous eBook. Illustrations.</summary>
    <simplified:pwid>614779bf-649d-8dda-9995-c444d9827494</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536222418/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536222418/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2021-09-14</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859749/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027859749</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859749" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:51+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859749/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859749/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027859749"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027859749/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Sophie the Squirrel</title>
    <author>
      <name>Small, Lily</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Small, Lily" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Small%2C%20Lily/eng/Children"/>
    </author>
    <schema:Series name="Fairy Animals of Misty Wood">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Fairy Animals of Misty Wood" href="https://qa-circulation.openebooks.us/USOEI/works/series/Fairy%20Animals%20of%20Misty%20Wood/eng/Children"/>
    </schema:Series>
    <summary type="html">Enter an enchanted world of fairy animal friends!Sophie the  Squirrel can't wait for the Misty Wood fair. She and her friends are  going to perform a special dance. But when they try to practice, things  keep going wrong. Sophie is upset--she's sure it's all her fault. Maybe  some Misty Wood magic is what she needs . . .</summary>
    <simplified:pwid>bcb057c5-48f4-b3e9-3bb8-181335756726</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250122599/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250122599/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>Henry Holt &amp; Co</bib:publisherImprint>
    <dcterms:issued>2017-02-28</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019333506/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0019333506</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019333506" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2023-10-08T17:14:48+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019333506/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019333506/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0019333506"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0019333506/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Straw House, Wood House, Brick House, Blow</title>
    <author>
      <name>Nayeri, Daniel</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Nayeri, Daniel" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Nayeri%2C%20Daniel/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <simplified:pwid>5f05ff54-bfb0-2448-fd29-1417900f58c4</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536223439/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536223439/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Short%20Stories" label="Short Stories"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2022-09-27</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859761/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027859761</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859761" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:51+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859761/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027859761/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027859761"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027859761/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Tia Isa Wants a Car</title>
    <contributor opf:role="ill">
      <name>Munoz, Claudio</name>
    </contributor>
    <author>
      <name>Medina, Meg</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Medina, Meg" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Medina%2C%20Meg/eng/Children"/>
    </author>
    <summary type="html">A little girl pitches in to help her tÃ­a save up for a big old car â€” and take the whole family to the beach â€” in a story told with warmth and sweetness.TÃ­a Isa wants a car. A shiny green car the same color as the ocean, with wings like a swooping bird. A car to take the whole family to the beach. But saving is hard when everything goes into two piles â€” one for here and one for Helping Money, so that family members who live far away might join them someday. While TÃ­a Isa saves, her niece does odd jobs for neighbors so she can add her earnings to the stack. But even with her help, will they ever have enough? Meg Medinaâ€™s simple, genuine story about keeping in mind those who are far away is written in lovely, lyrical prose and brought to life through Claudio MuÃ±ozâ€™s charming characters.</summary>
    <simplified:pwid>f23c36b7-0a6c-5cd8-4091-a557c1654895</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536219517/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536219517/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2020-06-05</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026727090/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026727090</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026727090" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:31+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026727090/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026727090/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026727090"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026727090/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Too Small Tola</title>
    <contributor opf:role="ill">
      <name>Iwu, Onyinye</name>
    </contributor>
    <author>
      <name>Atinuke</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Atinuke" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Atinuke/eng/Children"/>
    </author>
    <schema:Series name="Too Small Tola">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Too Small Tola" href="https://qa-circulation.openebooks.us/USOEI/works/series/Too%20Small%20Tola/eng/Children"/>
    </schema:Series>
    <summary type="html">Three delightful tales from a renowned Nigerian storyteller introduce a chapter-book heroine who is every bit as mighty as she is small.In a trio of droll stories, award-winning author and storyteller Atinuke debuts an endearing and enduring character with plenty to prove. Tola lives in an apartment in the busy city of Lagos, Nigeria, with her sister, Moji, who is very clever; her brother, Dapo, who is very fast; and Grandmommy, who is very bossy. Tola may be small, but sheâ€™s strong enough to carry a basket brimming with groceries home from the market, and sheâ€™s clever enough to count out Grandmommyâ€™s change. When the faucets in the apartment break, itâ€™s Tola who brings water from the well. And when Mr. Abdul, the tailor, has an accident and needs help taking his customersâ€™ measurements, only Tola can save the day. Atinukeâ€™s trademark wit and charm are on full display, accompanied by delightful illustrations by Onyinye Iwu. Too Small Tola evokes the urban bustle and rich blending of cultures in Lagos through the eyes of a little girl with an outsize willâ€”and an even bigger heart.</summary>
    <simplified:pwid>2ed82855-1f08-8d54-4635-612314d9131e</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536219654/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536219654/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="7-8" label="7-8"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2021-03-09</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063505/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027063505</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063505" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:48+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063505/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027063505/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027063505"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027063505/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>The Unexplainable Disappearance of Mars Patel</title>
    <author>
      <name>Chari, Sheela</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Chari, Sheela" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Chari%2C%20Sheela/eng/Children"/>
    </author>
    <schema:Series name="Mars Patel">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Mars Patel" href="https://qa-circulation.openebooks.us/USOEI/works/series/Mars%20Patel/eng/Children"/>
    </schema:Series>
    <summary type="html">A high-tech adventure based on the award-winning podcast finds the intrepid Mars Patel and his outcast friends investigating the disappearances of kids from their school before stumbling across an enigmatic billionaireâ€™s dark conspiracy. By the Edgar Award-winning author of Vanished. Simultaneous eBook.</summary>
    <simplified:pwid>764e21ae-5837-c9f2-e076-38b6241f55c7</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536216233/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536216233/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Mystery" label="Mystery"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Science%20Fiction" label="Science Fiction"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Walker Books Us</bib:publisherImprint>
    <dcterms:issued>2020-10-06</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425198/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0026425198</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425198" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:31+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425198/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0026425198/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0026425198"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0026425198/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Voice of Freedom</title>
    <author>
      <name>Holmes, Ekua</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Holmes, Ekua" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Holmes%2C%20Ekua/eng/Children"/>
    </author>
    <author>
      <name>Weatherford, Carole Boston</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Weatherford, Carole Boston" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Weatherford%2C%20Carole%20Boston/eng/Children"/>
    </author>
    <simplified:pwid>de33b85d-d7db-2fc1-5dfa-c512ae6f1448</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536227994/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536227994/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Biography%20%26%20Memoir" label="Biography &amp; Memoir"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Life%20Strategies" label="Life Strategies"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2022-02-01</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028672829/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0028672829</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028672829" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:52+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028672829/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028672829/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0028672829"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0028672829/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Weslandia</title>
    <author>
      <name>Hawkes, Kevin</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hawkes, Kevin" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hawkes%2C%20Kevin/eng/Children"/>
    </author>
    <author>
      <name>Fleischman, Paul</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Fleischman, Paul" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Fleischman%2C%20Paul/eng/Children"/>
    </author>
    <summary type="html">"This fantastical picture book, like its hero, is bursting at the seams with creativity. . . . a vigorous shot in the arm to nonconformists everywhere" â€” Publishers Weekly (starred review) Enter the witty, intriguing world of Weslandia! Now that school is over, Wesley needs a summer project. Heâ€™s learned that each civilization needs a staple food crop, so he decides to sow a garden and start his own â€” civilization, that is. He turns over a plot of earth, and plants begin to grow. They soon tower above him and bear a curious-looking fruit. As Wesley experiments, he finds that the plant will provide food, clothing, shelter, and even recreation. It isnâ€™t long before his neighbors and classmates develop more than an idle curiosity about Wesley â€” and exactly how he is spending his summer vacation.</summary>
    <simplified:pwid>76e330df-3a97-9a1e-005a-622190505abf</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536228007/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536228007/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2022-02-01</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028672831/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0028672831</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028672831" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:53+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028672831/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0028672831/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0028672831"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0028672831/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>X</title>
    <author>
      <name>Magoon, Kekla</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Magoon, Kekla" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Magoon%2C%20Kekla/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <author>
      <name>Shabazz, Ilyasah</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Shabazz, Ilyasah" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Shabazz%2C%20Ilyasah/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <summary type="html">A 2016 Coretta Scott King Author Honor BookCowritten by Malcolm Xâ€™s daughter, this riveting and revealing novel follows the formative years of the man whose words and actions shook the world.Malcolm Littleâ€™s parents have always told him that he can achieve anything, but from what he can tell, thatâ€™s a pack of liesâ€”after all, his fatherâ€™s been murdered, his motherâ€™s been taken away, and his dreams of becoming a lawyer have gotten him laughed out of school. Thereâ€™s no point in trying, he figures, and lured by the nightlife of Boston and New York, he escapes into a world of fancy suits, jazz, girls, and reefer. But Malcolmâ€™s efforts to leave the past behind lead him into increasingly dangerous territory. Deep down, he knows that the freedom heâ€™s found is only an illusionâ€”and that he canâ€™t run forever. X follows Malcolm from his childhood to his imprisonment for theft at age twenty, when he found the faith that would lead him to forge a new path and command a voice that still resonates today.</summary>
    <simplified:pwid>b8b4ac93-6edb-7360-6030-bd78dbd1ca3f</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780763674250/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780763674250/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Historical%20Fiction" label="Historical Fiction"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2015-01-06</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016025182/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016025182</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016025182" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:12+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016025182/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016025182/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016025182"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016025182/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Yucky Worms</title>
    <author>
      <name>Ahlberg, Jessica</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Ahlberg, Jessica" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Ahlberg%2C%20Jessica/eng/Children"/>
    </author>
    <author>
      <name>French, Vivian</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="French, Vivian" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/French%2C%20Vivian/eng/Children"/>
    </author>
    <schema:Series name="Read and Wonder">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Read and Wonder" href="https://qa-circulation.openebooks.us/USOEI/works/series/Read%20and%20Wonder/eng/Children"/>
    </schema:Series>
    <summary type="html">While helping Grandma in the garden, a child learns about the important role of the earthworm in helping plants grow.</summary>
    <simplified:pwid>3e625521-bf1e-7cf7-00ac-af4c9f9f2b31</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221121/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="https://image.mock/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781536221121/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Nature" label="Nature"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Gardening" label="Gardening"/>
    <category scheme="http://schema.org/audience" term="Children" label="Children"/>
    <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
    <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
    <dcterms:issued>2021-05-04</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027968746/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0027968746</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027968746" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2023-09-06T00:00:00Z</published>
    <updated>2023-10-08T17:17:51+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027968746/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0027968746/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0027968746"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0027968746/open_book"/>
  </entry>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/614?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full" title="Title" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by" opds:activeFacet="true"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/614?entrypoint=Book&amp;order=author&amp;available=now&amp;collection=full" title="Author" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/feed/614?entrypoint=Book&amp;order=added&amp;available=now&amp;collection=full" title="Recently Added" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link rel="next" href="https://qa-circulation.openebooks.us/USOEI/feed/614?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full&amp;key=%5B%22%5Cu2ca8%5Cu4b4f%5Cu2b20%5Cu4554%5Cu2a5a%5Cu0534%5Cu021e%5Cu01dc%5Cu60f0%5Cu0200%5Cu0000%5Cu0000%22%2C+%22%5Cu149b%5Cu4fca%5Cu6629%5Cu3350%5Cu3021%5Cu6cc5%5Cu1a9a%5Cu392d%5Cu1480%5Cu4500%5Cu3b97%5Cu6dc0%5Cu5000%5Cu0001%22%2C+15731%5D&amp;size=50"/>
  <link rel="start" href="https://qa-circulation.openebooks.us/USOEI/groups/" title="All Books"/>
  <simplified:breadcrumbs>
    <link title="All Books" href="https://qa-circulation.openebooks.us/USOEI/groups/"/>
    <link title="Book" href="https://qa-circulation.openebooks.us/USOEI/groups/?entrypoint=Book"/>
  </simplified:breadcrumbs>
  <link rel="http://opds-spec.org/auth/document" href="https://qa-circulation.openebooks.us/USOEI/authentication_document"/>
  <link rel="search" type="application/opensearchdescription+xml" href="https://qa-circulation.openebooks.us/USOEI/search/614?entrypoint=All&amp;order=title&amp;available=now&amp;collection=full"/>
  <link rel="http://opds-spec.org/shelf" type="application/atom+xml;profile=opds-catalog;kind=acquisition" href="https://qa-circulation.openebooks.us/USOEI/loans/"/>
  <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/"/>
  <link rel="http://opds-spec.org/crawlable" type="application/atom+xml;profile=opds-catalog;kind=acquisition" href="https://qa-circulation.openebooks.us/USOEI/lists/Manual%20Grid%20Component%20Test%20Book%20List/crawlable"/>
  <link href="http://openebooks.net/index.html" title="Home" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/about.html" title="About" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/faq.html" title="FAQs" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/resources.html" title="Resources" type="text/html" rel="related" role="navigation"/>
  <link href="https://openebooks.zendesk.com/" title="Help Center" type="text/html" rel="related" role="navigation"/>
  <link href="mailto:simplyedev@nypl.org" rel="help"/>
</feed>
`;
