const groups = `
<feed xmlns="http://www.w3.org/2005/Atom" xmlns:app="http://www.w3.org/2007/app" xmlns:bib="http://bib.schema.org/" xmlns:bibframe="http://bibframe.org/vocab/" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:drm="http://librarysimplified.org/terms/drm" xmlns:lcp="http://readium.org/lcp-specs/ns" xmlns:opds="http://opds-spec.org/2010/catalog" xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" xmlns:opf="http://www.idpf.org/2007/opf" xmlns:schema="http://schema.org/" xmlns:simplified="http://librarysimplified.org/terms/" simplified:entryPoint="http://schema.org/EBook">
   <id>https://qa-circulation.openebooks.us/USOEI/groups/?entrypoint=Book</id>
   <title>Open eBooks (QA Server)</title>
   <updated>2022-01-04T20:35:06+00:00</updated>
   <link href="https://qa-circulation.openebooks.us/USOEI/groups/?entrypoint=Book" rel="self" />
   <entry schema:additionalType="http://schema.org/EBook">
      <title>A Boy, a Ball, and a Dog</title>
      <author>
         <name>Marino, Gianna</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Marino, Gianna" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Marino%2C%20Gianna/eng/Children" />
      </author>
      <summary type="html">There was never a ball the boy wouldn't throw.Luckily, there was never a ball that his dog couldn't catch.When  a new kind of ball (a balloon)  floats into the picture, both boy and  dog try to find a way to play with it, and they become separated. Will  they find their way back to each other and finish their game?</summary>
      <simplified:pwid>eb176ce9-ceb0-7637-658c-3a32494f416a</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781626728271/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781626728271/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Roaring Brook</bib:publisherImprint>
      <dcterms:issued>2016-06-21</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018923188/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0018923188</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018923188" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2018-08-07T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/feed/440?entrypoint=Book" title="Books for integration testing" />
      <updated>2022-01-04T04:24:40+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018923188/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018923188/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0018923188" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0018923188/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Good Night, Baby Animals You've Had a Busy Day</title>
      <contributor opf:role="ill">
         <name>Watkins, Laura</name>
      </contributor>
      <author>
         <name>Winnick, Karen B.</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Winnick, Karen B." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Winnick%2C%20Karen%20B./eng/Children" />
      </author>
      <summary type="html">Whether they're dashing, splashing, munching, or crunching, baby animals  are ever-exploring! At home in their natural habitats, baby animals  learn about the world that surrounds them. When the fun is done, it's  time for baby animals to go to sleep. After all, every baby animal has  to rest.Full of action, sound effects, and patterned text, each  story features the adorable antics and accurate behavior of six wild  animal babies: tiger, elephant, giraffe, rhinoceros, panda, and gorilla.  This 64-page, beautifully designed story book is perfect for bedtime.</summary>
      <simplified:pwid>cd145f9d-5a32-d3b0-64b7-e6779260dea3</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250152596/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250152596/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Henry Holt &amp; Co</bib:publisherImprint>
      <dcterms:issued>2017-01-24</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0020037525</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2018-08-07T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/feed/440?entrypoint=Book" title="Books for integration testing" />
      <updated>2022-01-04T04:26:21+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020037525/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0020037525" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0020037525/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Almost Midnight</title>
      <author>
         <name>Hunter, C. C.</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hunter, C. C." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <schema:Series name="Shadow Falls: After Dark">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Shadow Falls: After Dark" href="https://qa-circulation.openebooks.us/USOEI/works/series/Shadow%20Falls:%20After%20Dark/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </schema:Series>
      <summary type="html">A vampire and a werewolf who never fit in. A witch whose spells don't always work as planned. And a mysterious new guy who's hiding something. Together they will discover who they're meant to be...Hidden from the human world, Shadow Falls is a secret camp for teens with supernatural powers. But for four very different teens, it's much more than just a camp-it's where their destinies will unfold.Independent and strong-willed Della Tsang didn't believe in vampires...until she became one. Chase Tallman is the newest member of Shadow Falls, but what made him into the sexy, mysterious vampire he is today? And what led him to Della Tsang? For Miranda Kane, magic has always been something she's struggled with-until she finally has a chance to prove herself a witch to be reckoned with. And for Fredericka Lakota, the one guy who challenges her the most will lead her to the life she's always wanted.C. C. Hunter's New York Times bestselling Shadow Falls series captured hearts all over the world. Now available in print for the first time, these enchanting novellas will reveal a side of the camp that you've never seen before...and live in your heart forever. Fans won't want to miss these four remarkable stories of love, magic and friendship in Almost Midnight.</summary>
      <simplified:pwid>e2907ae6-0cff-a26a-90ec-2bb676a39efb</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466892859/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466892859/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Suspense/Thriller" label="Suspense/Thriller" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Paranormal%20Romance" label="Paranormal Romance" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>St Martins Pr Griffin</bib:publisherImprint>
      <dcterms:issued>2016-02-02</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0017409891</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2018-08-07T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/feed/440?entrypoint=Book" title="Books for integration testing" />
      <updated>2022-01-04T14:30:17+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0017409891" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0017409891/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>D Is for Drama</title>
      <author>
         <name>Whittemore, Jo</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Whittemore, Jo" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Whittemore%2C%20Jo/eng/Children" />
      </author>
      <schema:Series name="Mix">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Mix" href="https://qa-circulation.openebooks.us/USOEI/works/series/Mix/eng/Children" />
      </schema:Series>
      <summary type="html">A lead role means lots of drama in this sparkling story of one tween&amp;amp;;s efforts to shine in the spotlight. Sunny Kim is done with one-line roles at Carnegie Arts Academy&amp;amp;;she&amp;amp;;s ready for the lead. But even after a summer of studying with an acting coach, Sunny doesn&amp;amp;;t snag the role of Mary Poppins in her school&amp;amp;;s upcoming production. &amp;amp;nbsp;&amp;amp;nbsp;&amp;amp;nbsp;&amp;amp;nbsp; Unfortunately, her entire family mistakenly thinks otherwise, including her former-actress mother. &amp;amp;nbsp;&amp;amp;nbsp;&amp;amp;nbsp;&amp;amp;nbsp; Desperate for a solution, Sunny convinces her theater adviser to let her produce a one-woman show. But when the rest of her friends find out&amp;amp;;the friends that never seem to make the playbill either&amp;amp;;they all want to join in. Before long, Sunny is knee-deep in curtains, catfights, and chorus lines as her one-woman work turns into a staging of the hit musical Wicked. And when a terrible misunderstanding pits the entire cast against Sunny, can the show&amp;amp;;and Sunny&amp;amp;;s future acting career&amp;amp;;be saved in time for opening night?</summary>
      <simplified:pwid>0912a4f7-ff6b-254d-515c-ef22e7d397c8</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442441538/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442441538/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Humorous%20Fiction" label="Humorous Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Aladdin</bib:publisherImprint>
      <dcterms:issued>2012-08-07</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686310/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016686310</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686310" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-18T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/feed/440?entrypoint=Book" title="Books for integration testing" />
      <updated>2022-01-04T04:10:00+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686310/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016686310/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016686310" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016686310/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>John F. Kennedy</title>
      <contributor opf:role="ill">
         <name>Fiorentino, Al</name>
      </contributor>
      <author>
         <name>Frisbee, Lucy Post</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Frisbee, Lucy Post" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Frisbee%2C%20Lucy%20Post/eng/Children" />
      </author>
      <schema:Series name="History's All-stars">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="History's All-stars" href="https://qa-circulation.openebooks.us/USOEI/works/series/History%27s%20All-stars/eng/Children" />
      </schema:Series>
      <summary type="html">When young Jack Kennedy's little sister gets tired of playing, Jack suggests a a sail. Within moments Jack is steering the small chip toward Osterville to say hello to Captain Manley. Under darkening clouds the two children head back home -- but they do not make it before a storm hits, threatening their lives. Is young Jack a good enough sailor to bring his sister home to safety?</summary>
      <simplified:pwid>f23dff14-b1c0-3bdb-d8c6-63e406dc9b2a</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781481430777/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781481430777/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Historical%20Fiction" label="Historical Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Aladdin</bib:publisherImprint>
      <dcterms:issued>2015-01-13</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016692239</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-20T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/feed/440?entrypoint=Book" title="Books for integration testing" />
      <updated>2022-01-04T04:13:12+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016692239" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016692239/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Zap</title>
      <author>
         <name>Fleischman, Paul</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Fleischman, Paul" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Fleischman%2C%20Paul/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <summary type="html">Wanting to put on a show like no other, the high-school drama club decides to mix, match, and mingle various plays from several authors, including Neil Simon and Tennessee Williams, in order to create something that is sure to keep their audience guessing to the very end. Reprint.</summary>
      <simplified:pwid>a876eb58-0e6a-aa8c-1d03-10e0564dbd2a</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="https://covers.nypl.org/Content%20Cafe/ISBN/9780763688387/cover.jpg" type="image/jpeg" />
      <link rel="http://opds-spec.org/image/thumbnail" href="https://covers.nypl.org/scaled/300/Content%20Cafe/ISBN/9780763688387/cover.jpg" type="image/jpeg" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Classics" label="Classics" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
      <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
      <dcterms:issued>2015-08-04</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017459949/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0017459949</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017459949" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2017-01-17T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:22:40+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017459949/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017459949/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0017459949" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0017459949/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Wise Young Fool</title>
      <author>
         <name>Beaudoin, Sean</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Beaudoin, Sean" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Beaudoin%2C%20Sean/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <summary type="html">Ritchie Sudden, a teenaged guitarist in a rock band, deals with loss and anger as he relates the events that landed him in a juvenile detention center. 25,000 first printing.</summary>
      <simplified:pwid>ea04d5a5-a5e3-6342-dc86-7ce36e0783bb</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316235112/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316235112/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
      <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
      <dcterms:issued>2013-08-06</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215783/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013215783</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215783" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-09-01T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:01:14+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215783/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215783/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013215783" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013215783/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Outpost</title>
      <author>
         <name>Aguirre, Ann</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Aguirre, Ann" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Aguirre%2C%20Ann/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <schema:Series name="The Razorland Trilogy">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="The Razorland Trilogy" href="https://qa-circulation.openebooks.us/USOEI/works/series/The%20Razorland%20Trilogy/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </schema:Series>
      <summary type="html">Deuce's whole world has changed. Down below, she was considered an adult. Now, topside in a town called Salvation, she's a brat in need of training in the eyes of the townsfolk. She doesn't fit in with the other girls: Deuce only knows how to fight. To make matters worse, her Hunter partner, Fade, keeps Deuce at a distance. Her feelings for Fade haven't changed, but he seems not to want her around anymore. Confused and lonely, she starts looking for a way out. Deuce signs up to serve in the summer patrols—those who make sure the planters can work the fields without danger. It should be routine, but things have been changing on the surface, just as they did below ground. The Freaks have grown smarter. They're watching. Waiting. Planning. The monsters don't intend to let Salvation survive, and it may take a girl like Deuce to turn back the tide.</summary>
      <simplified:pwid>7ebe975b-25e3-09b3-a734-b01d89baa4a6</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250031402/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250031402/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Dystopian%20SF" label="Dystopian SF" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Feiwel &amp; Friends</bib:publisherImprint>
      <dcterms:issued>2012-09-04</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0011501461/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0011501461</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0011501461" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-10-13T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:16:40+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0011501461/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0011501461/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0011501461" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0011501461/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>The World Beneath</title>
      <author>
         <name>Warman, Janice</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Warman, Janice" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Warman%2C%20Janice/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <summary type="html">Growing up in the maid's room of a wealthy white employer's home in 1970s South Africa, young Joshua rescues a stranger amid anti-apartheid riots sweeping through the country, which compel him to make heartbreaking choices. By the author of The Class of '79.</summary>
      <simplified:pwid>d2a46014-0f80-4e37-e44d-feb4c76925f6</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="https://covers.nypl.org/Content%20Cafe/ISBN/9780763680572/cover.jpg" type="image/jpeg" />
      <link rel="http://opds-spec.org/image/thumbnail" href="https://covers.nypl.org/scaled/300/Content%20Cafe/ISBN/9780763680572/cover.jpg" type="image/jpeg" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Historical%20Fiction" label="Historical Fiction" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Candlewick Pr Inc</dcterms:publisher>
      <bib:publisherImprint>Candlewick Pr Inc</bib:publisherImprint>
      <dcterms:issued>2016-05-10</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018669919/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0018669919</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018669919" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2017-01-17T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:22:59+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018669919/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018669919/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0018669919" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0018669919/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Northanger Abbey</title>
      <author>
         <name>Austen, Jane</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Austen, Jane" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Austen%2C%20Jane/eng/Adult%2CAdults%2BOnly%2CAll%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <summary type="html">Jane Austen's first novel, Northanger Abbey'published posthumously in 1818'tells the story of Catherine Morland and her dangerously sweet nature, innocence, and sometime self-delusion. Though Austen's fallible heroine is repeatedly drawn into scrapes while vacationing at Bath and during her subsequent visit to Northanger Abbey, Catherine eventually triumphs, blossoming into a discerning woman who learns truths about love, life, and the heady power of literature. The satirical Northanger Abbey pokes fun at the gothic novel while earnestly emphasizing caution to the female sex.This Modern Library Paperback Classic is set from the first edition of 1818.</summary>
      <simplified:pwid>167d751d-f5e0-5ec1-fbfd-cf79201a1b07</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780553903966/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780553903966/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Classics" label="Classics" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Literary%20Fiction" label="Literary Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Humorous%20Fiction" label="Humorous Fiction" />
      <category scheme="http://schema.org/audience" term="Adult" label="Adult" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Random House Digital</dcterms:publisher>
      <bib:publisherImprint>Bantam Classic &amp; Loveswept</bib:publisherImprint>
      <dcterms:issued>2007-07-31</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009225297/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0009225297</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009225297" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2018-08-15T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:26:58+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009225297/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009225297/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0009225297" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0009225297/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Green Algae and Bubble Gum Wars</title>
      <author>
         <name>Bryant, Annie</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Bryant, Annie" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Bryant%2C%20Annie/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <schema:Series name="Beacon Street Girls">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Beacon Street Girls" href="https://qa-circulation.openebooks.us/USOEI/works/series/Beacon%20Street%20Girls/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </schema:Series>
      <summary type="html">Science isn't exactly Maeve's favorite subject, but she's still excited to be going to the Sally Ride Science Festival at MIT with her hunky tutor, Matt. Sure, the BSG and her annoyingly brilliant younger brother are going as well, but it's still almost kind of a date, isn't it?   The festival gives the BSG a super idea -- an environmental science fair at Abigail Adams Junior High. But plans for a bubble gum factory nearby put Avery and Katani on opposite sides of an environmental issue -- and Avery finds herself in a bubble gum war with the Queens of Mean!</summary>
      <simplified:pwid>a3ac096c-4d1e-d8c7-f8d1-9c22a97779ef</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781439159620/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781439159620/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Aladdin</bib:publisherImprint>
      <dcterms:issued>2008-10-21</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676117/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016676117</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676117" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-15T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:07:51+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676117/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676117/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016676117" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016676117/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Perfect</title>
      <author>
         <name>Ahern, Cecelia</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Ahern, Cecelia" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Ahern%2C%20Cecelia/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <schema:Series name="Flawed">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Flawed" href="https://qa-circulation.openebooks.us/USOEI/works/series/Flawed/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </schema:Series>
      <summary type="html">In Perfect, Cecelia Ahern's thrilling sequel to Flawed, Celestine must make a choice: save just herself or risk her own life to save all Flawed people. Celestine North lives in a society that demands perfection. After she was branded Flawed by a morality court, Celestine's life has completely fractured--all her freedoms gone. Since Judge Crevan has declared her the number one threat to the public, she has been a ghost, on the run with Carrick--the only person she can trust. But Celestine has a secret--one that could bring the entire Flawed system crumbling to the ground. A secret that has already caused countless people to go missing. Judge Crevan is gaining the upper hand, and time is running out for Celestine. With tensions building, can she prove that to be human in itself is to be Flawed?</summary>
      <simplified:pwid>c09433ed-cbe0-d9cd-3584-1ffa92735e86</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250135612/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250135612/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Dystopian%20SF" label="Dystopian SF" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Feiwel &amp; Friends</bib:publisherImprint>
      <dcterms:issued>2017-04-04</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019245814/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0019245814</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019245814" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2018-08-07T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:26:51+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019245814/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019245814/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0019245814" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0019245814/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Pretty Girl-13</title>
      <author>
         <name>Coley, Liz</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Coley, Liz" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Coley%2C%20Liz/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <summary type="html">A disturbing and powerful psychological thriller about a girl who must piece together the mystery of her kidnapping and abuse, Pretty Girl-13 is a haunting yet ultimately uplifting story about the healing power of courage, hope, and love.Angie Chapman was thirteen years old when she ventured into the woods on a Girl Scout camping trip. Now she's returned home...only to find that it's three years later and she's sixteen'or at least that's what everyone tells her. What happened to the last three years of her life?With a tremendous amount of courage, Angie embarks on a journey to discover the fragments of her lost time. She eventually discovers a terrifying secret and must decide: What do you do when you remember things you wish you could forget?Perfect for fans of books like Elizabeth Scott's Living Dead Girl and Kathleen Glasgow's Girl in Pieces.</summary>
      <simplified:pwid>6ed31241-b196-9b80-b40f-9b31a2b23165</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780062127389/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780062127389/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>HarperCollins</dcterms:publisher>
      <bib:publisherImprint>Katherine Tegen Books</bib:publisherImprint>
      <dcterms:issued>2013-03-19</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0011466166/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0011466166</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0011466166" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-09-21T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:15:32+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0011466166/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0011466166/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0011466166" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0011466166/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Saturday Night Dirt</title>
      <author>
         <name>Weaver, Will</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Weaver, Will" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Weaver%2C%20Will/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <schema:Series name="Motor Novels">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Motor Novels" href="https://qa-circulation.openebooks.us/USOEI/works/series/Motor%20Novels/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </schema:Series>
      <summary type="html">It's a sizzling summer Saturday, and Headwaters Speedway has suddenly become the place to be. Thanks to rainouts across the state, this small-town dirt track is drawing both big-time stock cars and local drivers. There's Trace Bonham, whose Street Stock Chevy is acting up in a big way. And Beau Kim, whose "stone soup" Modified has been patched together from whatever parts he could scrape up. And no one could forget Amber Jenkins, a strawberry blonde who has what it takes to run rings around them all. Keeping everyone on track is Melody Walters, who knows that the impending rain might be exactly what they need to keep her father's speedway afloat—or sink it for good.In Will Weaver's high-revving novel, the first in the Motor series, a cast of car-obsessed teens and adults are all out to prove themselves, both on and off the quarter-mile track, as they move through their day on a collision course to meet on Saturday night dirt.Saturday Night Dirt is a 2009 Bank Street - Best Children's Book of the Year.</summary>
      <simplified:pwid>caed13fa-c889-0dfc-59e8-2c318434031d</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781429934466/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781429934466/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Farrar Straus &amp; Giroux</bib:publisherImprint>
      <dcterms:issued>2009-04-14</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009894935/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0009894935</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009894935" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-10-13T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:16:37+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009894935/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009894935/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0009894935" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0009894935/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Dakota Dream</title>
      <author>
         <name>Bennett, James W.</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Bennett, James W." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Bennett%2C%20James%20W./eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <summary type="html">An ALA Best Book for Young Adults: To fulfill his destiny, a teenage boy takes refuge on an Indian reservation Ever since he can remember, Floyd Rayfield has wanted to be a Native American. Abandoned by his family and raised in foster care, Floyd has never had a sense of who he was meant to be until the night he has the dream. He sees himself as a warrior of the Sioux Indians and knows that it is his destiny to make the dream come true. He dyes his hair black, instructs his teachers to call him Charly Black Crow, and learns everything he can about the people he admires so much. When the society he has rejected pushes back, Floyd has no other option but to run. He takes refuge on the sprawling Pine Ridge Reservation. Living among actual Native Americans, Floyd learns more about their way of life than he could ever have imagined. By getting in touch with someone else&amp;rsquo;s identity, he hopes finally to discover his own.</summary>
      <simplified:pwid>bf250e61-c905-ced3-e84c-c37cf796ccac</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781497684027/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781497684027/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Open Road Media</dcterms:publisher>
      <bib:publisherImprint>Open Road Media Teen &amp; Tween</bib:publisherImprint>
      <dcterms:issued>2015-04-07</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016664570/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016664570</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016664570" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2018-05-11T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:24:20+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016664570/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016664570/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016664570" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016664570/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Stone in the Sky</title>
      <author>
         <name>Castellucci, Cecil</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Castellucci, Cecil" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Castellucci%2C%20Cecil/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <schema:Series name="Tin Star">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Tin Star" href="https://qa-circulation.openebooks.us/USOEI/works/series/Tin%20Star/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </schema:Series>
      <summary type="html">Brother Blue.His name, even the color, filled me with a furious fire of pure hatred.Years ago, Tula Bane was beaten and left for dead on a remote space station far from Earth, her home planet. She started with nothing and had no one, but over time, she found a home, a family, and even love. When it's discovered that the abandoned planet beneath the station is abundant with a rare and valuable resource, aliens from across the galaxy race over to strike it rich. With them comes trouble, like the man who nearly killed Tula years ago—the man she has dreamed of destroying ever since.In this sequel to Tin Star, Cecil Castellucci takes readers on an extraordinary adventure through space in a thrilling and thoughtful exploration of what it means to love, to hate, and to be human.</summary>
      <simplified:pwid>fd7af094-7c17-9711-5380-5783cf6c608e</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781626721517/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781626721517/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Science%20Fiction" label="Science Fiction" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Roaring Brook</bib:publisherImprint>
      <dcterms:issued>2015-02-24</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015469752/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0015469752</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015469752" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-10-13T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:17:14+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015469752/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0015469752/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0015469752" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0015469752/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Super Stock Rookie</title>
      <author>
         <name>Weaver, Will</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Weaver, Will" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Weaver%2C%20Will/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <schema:Series name="Motor Novels">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Motor Novels" href="https://qa-circulation.openebooks.us/USOEI/works/series/Motor%20Novels/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </schema:Series>
      <summary type="html">After a couple of seasons of small-town racing, things are shifting into high gear for a young dirt-track driver with the skills to make it big. Trace Bonham has landed a corporate sponsor and a custom-built dream ride. But this means Trace can no longer pilot his dad's Street Stock Chevy, and must let a new kid get behind the wheel. It also means having to turn his back on his hometown speedway, which his team leaders think is a hayseed operation not worth their time, even though it's run by a girl who matters to Trace in a big way. Filled with authentic race-car action and detail, Will Weaver's fast-paced novel is the story of a boy struggling with the speed and demands of his own success.</summary>
      <simplified:pwid>02ccf4a8-b537-b34e-436a-d824f21127f6</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781429948166/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781429948166/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Farrar Straus &amp; Giroux</bib:publisherImprint>
      <dcterms:issued>2010-04-27</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009062264/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0009062264</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009062264" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-10-13T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:16:26+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009062264/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0009062264/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0009062264" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0009062264/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Twilight</title>
      <author>
         <name>Meyer, Stephenie</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Meyer, Stephenie" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Meyer%2C%20Stephenie/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <schema:Series name="The Twilight Saga">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="The Twilight Saga" href="https://qa-circulation.openebooks.us/USOEI/works/series/The%20Twilight%20Saga/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </schema:Series>
      <summary type="html">Bored with her new life in a rainy Washington town, Isabella Swan finds her situation transforming into one of thrills and terror when she becomes involved with alluring vampire Edward Cullen, who struggles to keep his identity a secret. 100,000 first printing.</summary>
      <simplified:pwid>0b8cf681-5b44-a28f-0d2d-618ef70d7e81</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316137690/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316137690/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Romance" label="Romance" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Horror" label="Horror" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
      <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
      <dcterms:issued>2007-07-18</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215470/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013215470</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215470" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-27T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:15:10+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215470/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215470/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013215470" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013215470/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Rules of Summer</title>
      <author>
         <name>Philbin, Joanna</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Philbin, Joanna" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Philbin%2C%20Joanna/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </author>
      <schema:Series name="Rules of Summer">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Rules of Summer" href="https://qa-circulation.openebooks.us/USOEI/works/series/Rules%20of%20Summer/eng/All%2BAges%2CChildren%2CYoung%2BAdult" />
      </schema:Series>
      <summary type="html">Taking a summer job in East Hampton where she is warned to avoid socializing with her wealthy employers, 17-year-old errand girl Rory falls for her employer's son and forges a friendship with Isabel, who pursues a forbidden whirlwind romance against a backdrop of dark secrets. By the author of the Daughters series. 50,000 first printing.</summary>
      <simplified:pwid>9e09da73-83b6-6cc2-7e48-5b4f686d54c3</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316244206/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316244206/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Contemporary%20Romance" label="Contemporary Romance" />
      <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult" />
      <category scheme="http://schema.org/typicalAgeRange" term="15-18" label="15-18" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
      <bib:publisherImprint>Poppy</bib:publisherImprint>
      <dcterms:issued>2013-06-04</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215847/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0013215847</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215847" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-09-01T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/406?entrypoint=Book" title="High School" />
      <updated>2022-01-04T04:01:16+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215847/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0013215847/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0013215847" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0013215847/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Double Identity</title>
      <author>
         <name>Haddix, Margaret Peterson</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Haddix, Margaret Peterson" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Haddix%2C%20Margaret%20Peterson/eng/Children" />
      </author>
      <summary type="html">So my only protection is a kindergarten teacher and a ninety-eight-pound female minister....And they don't even believe I'm in danger.  As Bethany approaches her thirteenth birthday, her parents begin acting more oddly than usual: Her mother cries constantly, and her father barely lets Bethany out of his sight. Then one morning he hustles the entire family into the car, drives across several state lines -- and leaves Bethany with an aunt she never knew existed. Bethany has no idea what's going on. She's worried that her mom and dad are running from some kind of trouble, but she can't find out because they won't tell her where they are going.  Bethany's only clue is a few words she overheard her father tell her aunt Myrlie: "She doesn't know anything about Elizabeth." But Aunt Myrlie won't tell Bethany who Elizabeth is, and she won't explain why people in her small town react to Bethany as if they've seen a ghost. The mystery intensifies when Bethany gets a package from her father containing four different birth certificates from four states, with four different last names -- and thousands of dollars in cash. And when a strange man shows up asking questions, Bethany realizes she's not the only one who's desperate to unravel the secrets of her past.  In this exhilarating thriller, Margaret Peterson Haddix crafts a taut story so full of twists and turns, readers will be gripped until the startling conclusion.</summary>
      <simplified:pwid>ca423538-8d7b-47a1-062a-1b199c0a724a</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781439106655/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781439106655/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Mystery" label="Mystery" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Simon &amp; Schuster Books for Young</bib:publisherImprint>
      <dcterms:issued>2008-06-20</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016674847/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016674847</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016674847" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-15T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:02:49+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016674847/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016674847/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016674847" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016674847/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Shadows of Sherwood</title>
      <author>
         <name>Magoon, Kekla</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Magoon, Kekla" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Magoon%2C%20Kekla/eng/Children" />
      </author>
      <schema:Series name="Robyn Hoodlum Adventure">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Robyn Hoodlum Adventure" href="https://qa-circulation.openebooks.us/USOEI/works/series/Robyn%20Hoodlum%20Adventure/eng/Children" />
      </schema:Series>
      <summary type="html">"A compelling reboot of the Robin Hood myth." --Rick Riordan, bestselling author of Percy Jackson &amp; the OlympiansThe night her parents disappear, twelve-year-old Robyn Loxley must learn to fend for herself. Her home, Nott City, has been taken over by a harsh governor, Ignomus Crown. After fleeing for her life, Robyn has no choice but to join a band of strangers--misfit kids, each with their own special talent for mischief. Setting out to right the wrongs of Crown's merciless government, they take their outlaw status in stride. But Robyn can't rest until she finds her parents. As she pieces together clues from the night they disappeared, Robyn learns that her destiny is tied to the future of Nott City in ways she never expected.Read all the books in the Robyn Hoodlum series:Shadows of SherwoodRebellion of ThievesReign of Outlaws</summary>
      <simplified:pwid>ac9937c0-3727-0527-2fcf-90c26a4d7a08</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781619636354/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781619636354/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Folklore" label="Folklore" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Bloomsbury USA</dcterms:publisher>
      <bib:publisherImprint>Bloomsbury Childrens Books</bib:publisherImprint>
      <dcterms:issued>2015-08-04</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017377927/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0017377927</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017377927" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-09-01T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:06:26+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017377927/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017377927/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0017377927" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0017377927/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>The Black Cauldron 50th Anniversary Edition</title>
      <author>
         <name>Alexander, Lloyd</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Alexander, Lloyd" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Alexander%2C%20Lloyd/eng/Children" />
      </author>
      <schema:Series name="The Chronicles of Prydain">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="The Chronicles of Prydain" href="https://qa-circulation.openebooks.us/USOEI/works/series/The%20Chronicles%20of%20Prydain/eng/Children" />
      </schema:Series>
      <summary type="html">Henry Holt Books for Young Readers is proud to publish this 50th Anniversary Edition of Lloyd Alexander's classic Newbery Honor winner The Black Cauldron, the second book in the Chronicles of Prydain, with a new introduction by fellow Newbery Honor winner Rebecca Stead. In the land of Prydain, evil is never far away. Arawn, Lord of the Land of Death, has been building an army of dark warriors to take over Prydain, and the only way to stop him is to destroy the Black Cauldron he uses to create his dreaded soldiers. Taran the Assistant Pig-Keeper and his loyal companions must journey deep into Arawn's domain to destroy the Black Cauldron. For each of them, the quest has a special meaning.  For Taran, it is the glorious opportunity to use his first sword in battle.  But war requires a sacrifice greater than he'd ever imagined. . . .</summary>
      <simplified:pwid>9d3c10b9-4b87-efdb-9d89-7c7b1a20485f</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="https://covers.nypl.org/Content%20Cafe/ISBN/9781627795449/cover.jpg" type="image/jpeg" />
      <link rel="http://opds-spec.org/image/thumbnail" href="https://covers.nypl.org/scaled/300/Content%20Cafe/ISBN/9781627795449/cover.jpg" type="image/jpeg" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Folklore" label="Folklore" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Henry Holt &amp; Co</bib:publisherImprint>
      <dcterms:issued>2015-09-01</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016531996/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016531996</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016531996" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2017-01-04T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:21:30+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016531996/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016531996/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016531996" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016531996/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Kip Campbell's Gift</title>
      <author>
         <name>Paratore, Coleen</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Paratore, Coleen" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Paratore%2C%20Coleen/eng/Children" />
      </author>
      <schema:Series name="The Funeral Director's Son">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="The Funeral Director's Son" href="https://qa-circulation.openebooks.us/USOEI/works/series/The%20Funeral%20Director%27s%20Son/eng/Children" />
      </schema:Series>
      <summary type="html">Kip Campbell is still living in the town of Clover, still working in his family&amp;amp;;s funeral home, and yes, still speaking to the dead. Those souls who have passed on but aren&amp;amp;;t yet able to go to &amp;amp;;the good&amp;amp;; because something in their life was never resolved ask Kip for his help. As if these two jobs weren&amp;amp;;t enough, Kip also has to deal with girls and bullies. Now Kip is fed up and wants to quit talking to the dead, but can someone quit a job they never applied for?</summary>
      <simplified:pwid>81fa274b-0fd6-4f9f-e29a-94f19bf7e3c3</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781416996330/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781416996330/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Simon &amp; Schuster Books for Young</bib:publisherImprint>
      <dcterms:issued>2009-08-04</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676963/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016676963</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676963" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-14T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:09:38+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676963/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676963/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016676963" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016676963/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>The Fake Cape Caper</title>
      <contributor opf:role="ill">
         <name>Montijo, Rhode</name>
      </contributor>
      <author>
         <name>Trine, Greg</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Trine, Greg" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Trine%2C%20Greg/eng/Children" />
      </author>
      <summary type="html">When Melvin goes to Las Vegas for the Superhero's Convention, Candace is left in charge of Los Angeles. Between the horrible bullies at school and the devious and sinister Spaz brothers (Major and his brother, Big), Candace has her hands very full. And someone has stolen her cape! How will she stop all the bad guys before the city is overrun with crime?The super-sidekick gets her turn in the spotlight in this fifth hilarious book in the Melvin Beederman series.</summary>
      <simplified:pwid>977c4594-f0bf-0662-5216-5f379168fcc2</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="https://covers.nypl.org/Content%20Cafe/ISBN/9781250104748/cover.jpg" type="image/jpeg" />
      <link rel="http://opds-spec.org/image/thumbnail" href="https://covers.nypl.org/scaled/300/Content%20Cafe/ISBN/9781250104748/cover.jpg" type="image/jpeg" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Comics%20%26%20Graphic%20Novels" label="Comics &amp; Graphic Novels" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Humorous%20Fiction" label="Humorous Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Square Fish</bib:publisherImprint>
      <dcterms:issued>2015-11-24</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017787292/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0017787292</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017787292" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2017-01-04T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:22:13+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017787292/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017787292/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0017787292" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0017787292/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Hold Me Like a Breath</title>
      <author>
         <name>Schmidt, Tiffany</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Schmidt, Tiffany" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Schmidt%2C%20Tiffany/eng/Children" />
      </author>
      <summary type="html">In Penelope Landlow's world, almost anything can be bought or sold. She's the daughter of one of the three crime families controlling the black market for organ transplants. Because of an autoimmune disorder that causes her to bruise easily, Penny is considered too &amp;quot;delicate&amp;quot; to handle the family business, or even to step foot outside their estate. &amp;lt;br&amp;gt;&amp;lt;br&amp;gt;All Penelope has ever wanted is independence--until she's suddenly thrust into the dangerous world all alone, forced to stay one step ahead of her family's enemies. As she struggles to survive the power plays of rival crime families, she learns dreams come with casualties, betrayal hurts worse than bruises, and there's nothing she won't risk for the people she loves. &amp;lt;br&amp;gt;&amp;lt;br&amp;gt;Perfect for fans of Holly Black and Kimberly Derting, this first book in the stunning new Once Upon a Crime Family series from acclaimed author Tiffany Schmidt will leave readers breathless.</summary>
      <simplified:pwid>fcdb8b5c-bf08-bd20-7052-a119dfff197c</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780802738103/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780802738103/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Romance" label="Romance" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Folklore" label="Folklore" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Mystery" label="Mystery" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Bloomsbury USA</dcterms:publisher>
      <bib:publisherImprint>Bloomsbury Childrens Books</bib:publisherImprint>
      <dcterms:issued>2015-05-19</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016964483/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016964483</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016964483" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-09-01T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:06:23+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016964483/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016964483/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016964483" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016964483/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Megan 3</title>
      <author>
         <name>Hooper, Mary</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hooper, Mary" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hooper%2C%20Mary/eng/Children" />
      </author>
      <summary type="html">It is Jack's , Megan's son, first birthday party and the atmosphere is very tense. But Megan is being distracted by the unusual amount of telephone calls that her mother is getting. Surely Mum hasn't got a boyfriend has she? But it turns out that she has - and what is more, he is moving in. This really shakes up the dynamics of the house-hold, which Mary Hooper details with her accustomed very acccurate and precise eye for domestic detail. A must for all Megan fans.</summary>
      <simplified:pwid>42a795cb-8f98-645c-8819-bf9904c4ae30</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781408836798/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781408836798/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Bloomsbury Pub Plc</dcterms:publisher>
      <bib:publisherImprint>Bloomsbury Childrens Books</bib:publisherImprint>
      <dcterms:issued>2012-09-13</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014213671/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0014213671</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014213671" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-15T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:16:20+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014213671/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014213671/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0014213671" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0014213671/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Bringing Down the Mouse</title>
      <author>
         <name>Mezrich, Ben</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Mezrich, Ben" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Mezrich%2C%20Ben/eng/Children" />
      </author>
      <schema:Series name="Charlie Numbers Adventures">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Charlie Numbers Adventures" href="https://qa-circulation.openebooks.us/USOEI/works/series/Charlie%20Numbers%20Adventures/eng/Children" />
      </schema:Series>
      <summary type="html">A mathematically gifted sixth-grader is recruited by a group of students to game the system at the biggest theme park in the world--and win the big prize.</summary>
      <simplified:pwid>507ae034-94a0-ff71-b62b-426eb0622101</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442496323/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442496323/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Humorous%20Fiction" label="Humorous Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Simon &amp; Schuster Books for Young</bib:publisherImprint>
      <dcterms:issued>2014-06-24</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016691134/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016691134</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016691134" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-18T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:10:26+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016691134/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016691134/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016691134" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016691134/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Pendragon</title>
      <author>
         <name>Jablonski, Carla</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Jablonski, Carla" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Jablonski%2C%20Carla/eng/Children" />
      </author>
      <contributor opf:role="asn">
         <name>Machale, D. J.</name>
      </contributor>
      <schema:Series name="Pendragon: Before the War">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Pendragon: Before the War" href="https://qa-circulation.openebooks.us/USOEI/works/series/Pendragon:%20Before%20the%20War/eng/Children" />
      </schema:Series>
      <summary type="html">Before Bobby Pendragon. Before Saint Dane. Before the war . . . Every territory of Halla has a Traveler. They lived for years&amp;amp;#151;some even for decades&amp;amp;#151;before learning of their true destiny. What was life like for Bobby Pendragon's fellow Travelers before they joined him in the fight to save every time and place that has ever existed? What led up to their becoming the guardians of Halla? The answers are here! In this first of three thrilling Pendragon prequels, read about Vo Spader's death-defying adventures in the underwater world of Cloral, Gunny Van Dyke's race to find a murderer in 1930's Manhattan on First Earth, and the tough challenges Kasha faced on Eelong well before Bobby Pendragon arrived . . .</summary>
      <simplified:pwid>de29913f-4ce8-abf4-0b98-0bb805b65126</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781439153420/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781439153420/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Folklore" label="Folklore" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Aladdin</bib:publisherImprint>
      <dcterms:issued>2009-04-03</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676552/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016676552</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676552" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-14T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:14:28+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676552/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676552/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016676552" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016676552/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>My Father's Daughter</title>
      <author>
         <name>Konigsburg, E. L.</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Konigsburg, E. L." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Konigsburg%2C%20E.%20L./eng/Children" />
      </author>
      <summary type="html">Poor Little Rich Boy  Winston Carmichael has it all: a big house, servants, vacations in Palm Beach, and a fancy private school. But with overprotective parents and a sense of responsibility for his younger sister, Heidi, Winston sometimes feels more as if he's living in a prison than a dream.   Then one day a woman appears at the front door claiming to be Caroline -- Winston's half sister, who was kidnapped and presumed dead long before he and Heidi were born. Is she really Caroline? Is she an imposter? Or is she something far more complicated than either? And does she hold the key that could unlock the door to Winston's prison?</summary>
      <simplified:pwid>6167408e-750f-85fe-e850-54cd5c95ec39</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442439689/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442439689/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Mystery" label="Mystery" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Atheneum</bib:publisherImprint>
      <dcterms:issued>2011-05-24</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016149775/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016149775</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016149775" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-18T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:02:25+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016149775/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016149775/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016149775" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016149775/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>The Lethal Target</title>
      <author>
         <name>Eldridge, Jim</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Eldridge, Jim" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Eldridge%2C%20Jim/eng/Children" />
      </author>
      <summary type="html">Jake's girlfriend, Lauren, has been exiled to New Zealand by MI5, who ensure that the two of them never speak about the missing books of the Order of Malichea. The information in these books could destroy mankind if they fall into the wrong hands, but Jake and Lauren want to get to them first and use the wisdom for good. Then Lauren goes missing . . . When Jake follows the instructions of a mystery caller to go to a Scottish Island, he knows he could be in danger; the Russian government will do anything to get hold of one book in particular - a book that holds the secret to spontaneous human combustion. Jake has to race against time to intercept the book and prevent global warfare. Can he do it, and, at last, give Lauren her freedom?</summary>
      <simplified:pwid>ee8c6c1b-3da2-2018-70c0-83556a765b73</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781408826850/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781408826850/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Romance" label="Romance" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Mystery" label="Mystery" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Bloomsbury Pub Plc</dcterms:publisher>
      <bib:publisherImprint>Bloomsbury Childrens Books</bib:publisherImprint>
      <dcterms:issued>2013-04-11</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0012760423/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0012760423</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0012760423" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-09-08T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:12:31+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0012760423/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0012760423/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0012760423" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0012760423/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Danger &amp; Diamonds</title>
      <contributor opf:role="ill">
         <name>Gerstein, Mordicai</name>
      </contributor>
      <author>
         <name>Levy, Elizabeth</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Levy, Elizabeth" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Levy%2C%20Elizabeth/eng/Children" />
      </author>
      <schema:Series name="A Mystery at Sea">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="A Mystery at Sea" href="https://qa-circulation.openebooks.us/USOEI/works/series/A%20Mystery%20at%20Sea/eng/Children" />
      </schema:Series>
      <summary type="html">Phillipa has always loved Agatha Christie mysteries, but it's not until the new captain's son and some interesting guests come aboard the cruise ship where she lives (and her parents work), that she finally gets a chance to do some sleuthing of her own.</summary>
      <simplified:pwid>f255eff8-63a2-4fb3-d403-5ca4dfdd0122</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="https://covers.nypl.org/Content%20Cafe/ISBN/9781626726437/cover.jpg" type="image/jpeg" />
      <link rel="http://opds-spec.org/image/thumbnail" href="https://covers.nypl.org/scaled/300/Content%20Cafe/ISBN/9781626726437/cover.jpg" type="image/jpeg" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Mystery" label="Mystery" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Roaring Brook</bib:publisherImprint>
      <dcterms:issued>2015-12-08</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017889200/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0017889200</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017889200" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2017-01-04T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:22:17+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017889200/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017889200/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0017889200" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0017889200/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Science Comics</title>
      <contributor opf:role="ill">
         <name>Hirsch, Andy</name>
      </contributor>
      <author>
         <name>Hirsch, Andy</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hirsch, Andy" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hirsch%2C%20Andy/eng/Children" />
      </author>
      <schema:Series name="Science Comics">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Science Comics" href="https://qa-circulation.openebooks.us/USOEI/works/series/Science%20Comics/eng/Children" />
      </schema:Series>
      <summary type="html">Follows an acorn as it learns about its future as a tree, describing each stage of the plant's life from seed germination to maturation, and includes information on different types of trees and their role in the planet's ecosystem.</summary>
      <simplified:pwid>e35a39d3-922f-63fe-00eb-d0fcecd87a35</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250219312/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250219312/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>First Second</bib:publisherImprint>
      <dcterms:issued>2018-08-21</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0023613599/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0023613599</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0023613599" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2018-11-14T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T17:15:18+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0023613599/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0023613599/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0023613599" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0023613599/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Lulu's Mysterious Mission</title>
      <contributor opf:role="ill">
         <name>Cornell, Kevin</name>
      </contributor>
      <author>
         <name>Viorst, Judith</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Viorst, Judith" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Viorst%2C%20Judith/eng/Children" />
      </author>
      <schema:Series name="Lulu">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Lulu" href="https://qa-circulation.openebooks.us/USOEI/works/series/Lulu/eng/Children" />
      </schema:Series>
      <summary type="html">This irresistible illustrated chapter book starring Judith Viorst&amp;amp;;s Lulu is full of hilarious hijinks, delightful twists, and a top-secret mission!Eeny meeny miney mo,That babysitter&amp;amp;;s got to go. Lulu has put her tantrum-throwing days behind her. That is, until her parents announce that they are going on vacation&amp;amp;;WITHOUT LULU. Not only that, but they are leaving her with the formidable Ms. Sonia Sofia Solinsky, who says hello by bellowing, &amp;amp;;The Eagle has landed,&amp;amp;; and smiles at you with the kind of smile that an alligator might give you before eating you for dinner. The second her parents are out of the house, Lulu tries out several elaborate schemes to bring them straight back. But just when she seems to finally be making some headway, her babysitter reveals an astonishing secret&amp;amp;;one that has Lulu crossing her fingers that her parents will go on vacation all the time&amp;amp;;without her!</summary>
      <simplified:pwid>4604b382-8187-6d7a-5534-743a7a954cf2</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442497481/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442497481/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Humorous%20Fiction" label="Humorous Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Atheneum</bib:publisherImprint>
      <dcterms:issued>2014-04-08</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016690655/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016690655</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016690655" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-18T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/417?entrypoint=Book" title="Middle Grades" />
      <updated>2022-01-04T04:05:32+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016690655/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016690655/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016690655" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016690655/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>The Karate Clue</title>
      <author>
         <name>Dixon, Franklin W.</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Dixon, Franklin W." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Dixon%2C%20Franklin%20W./eng/Children" />
      </author>
      <schema:Series name="Hardy Boys Clues Bros.">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hardy Boys Clues Bros." href="https://qa-circulation.openebooks.us/USOEI/works/series/Hardy%20Boys%20Clues%20Bros./eng/Children" />
      </schema:Series>
      <summary type="html">Frank and Joe are learning some awesome moves in karate class. But somebody steals Brian Ludlow&amp;amp;;s black belt signed by action-movie hero Jimmy Han. Brian even promised to bring Jimmy to class, but now he says, &amp;amp;;No way!&amp;amp;; But maybe if the Hardys can find the belt, they&amp;amp;;ll be the true-blue action heroes of the day!</summary>
      <simplified:pwid>43bd0afb-461f-f8ce-2949-b586515a58d3</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781481402019/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781481402019/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Mystery" label="Mystery" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="7-8" label="7-8" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Aladdin</bib:publisherImprint>
      <dcterms:issued>2013-08-20</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016689048/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016689048</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016689048" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-18T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:14:04+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016689048/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016689048/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016689048" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016689048/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Princess Tales Around the World</title>
      <contributor opf:role="ill">
         <name>De Marcken, Gail</name>
      </contributor>
      <author>
         <name>MacCarone, Grace</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="MacCarone, Grace" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/MacCarone%2C%20Grace/eng/Children" />
      </author>
      <summary type="html">ONCE UPON A TIME… A princess laughed, A princess sang, Another made a trade.One told stories, One was kind, Another refused to wed.One cast spells, One was cursed, Another silently sewed every day. And one tossed golden apples away in ten fairy tales retold.In Princess Tales Around the World ten beloved princess stories, ingeniously told and gorgeously illustrated—with hidden pictures for readers to find—by author Grace Maccarone and artist Gail de Marcken, will delight readers, happily ever after.Tales include: Rapunzel, The Golden Goose, The Little Mermaid, The Firebird, Scheherazade, Princess Moonlight, The Princess on the Glass Hill, The Loathsome Dragon, Toads and Diamonds, and Six Swans, with Source Notes from the author.</summary>
      <simplified:pwid>f41e7342-95f8-7787-a88d-19f3fc9e5b21</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250153593/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250153593/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Folklore" label="Folklore" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Feiwel &amp; Friends</bib:publisherImprint>
      <dcterms:issued>2017-02-07</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019973599/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0019973599</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019973599" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2018-08-07T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:26:17+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019973599/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019973599/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0019973599" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0019973599/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Squirrels Leap, Squirrels Sleep</title>
      <contributor opf:role="ill">
         <name>Jenkins, Steve</name>
      </contributor>
      <author>
         <name>Sayre, April Pulley</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Sayre, April Pulley" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Sayre%2C%20April%20Pulley/eng/Children" />
      </author>
      <summary type="html">Squirrels wrestle.Squirrels leap.Squirrels climb.Squirrels sleep.If you followed a squirrel for a day, what would you see? Climbing branches, storing seeds, making homes in tree holes, and maybe even flying! Acclaimed nature writer April Pulley Sayre's lyrical text, paired with Steve Jenkins's stunning art, offers a glimpse into this fascinating world.Discover different kinds of squirrels as the scamper, eat, and—finally—sleep.</summary>
      <simplified:pwid>1cee48d5-d580-5a49-1c8b-7071d4b1bfc3</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250141477/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250141477/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Nature" label="Nature" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="6-7" label="6-7" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Henry Holt &amp; Co</bib:publisherImprint>
      <dcterms:issued>2016-11-01</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019684358/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0019684358</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019684358" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2018-08-07T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:26:11+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019684358/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019684358/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0019684358" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0019684358/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Who Took the Book?</title>
      <author>
         <name>Dixon, Franklin W.</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Dixon, Franklin W." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Dixon%2C%20Franklin%20W./eng/Children" />
      </author>
      <schema:Series name="Hardy Boys Clues Bros.">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hardy Boys Clues Bros." href="https://qa-circulation.openebooks.us/USOEI/works/series/Hardy%20Boys%20Clues%20Bros./eng/Children" />
      </schema:Series>
      <summary type="html">A really cool book of baseball statistics was stolen from the school library'and it turns up in Frank's backpack! The Hardys know Frank is being framed, but finding the real thief and clearing Frank's name means standing up to the meanest, toughest bullies in school.</summary>
      <simplified:pwid>7f342b21-38d7-a933-b308-88113f115d14</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781481406239/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781481406239/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Mystery" label="Mystery" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="7-8" label="7-8" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Aladdin</bib:publisherImprint>
      <dcterms:issued>2013-09-17</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016689284/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016689284</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016689284" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-18T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:14:06+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016689284/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016689284/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016689284" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016689284/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Bad Kitty Takes the Test</title>
      <author>
         <name>Bruel, Nick</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Bruel, Nick" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Bruel%2C%20Nick/eng/Children" />
      </author>
      <schema:Series name="Bad Kitty">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Bad Kitty" href="https://qa-circulation.openebooks.us/USOEI/works/series/Bad%20Kitty/eng/Children" />
      </schema:Series>
      <summary type="html">It's something everyone has to do at least once. Kitty is no different. In this new edition of the beloved, bestselling Bad Kitty series from author-illustrator Nick Bruel, Bad Kitty takes. . . the test!Based on her previous bad behavior, the Society of Cat Aptitude has determined that Kitty is not only a bad kitty but a bad cat. In order to redeem her feline status, Kitty must take an aptitude test to determine if she deserves to be a cat. If she fails, she will no longer be able to be a cat. With the help of Chatty Kitty, who is the instructor at Cat School, and Uncle Murray, who thinks he's just there to renew his driver's license, Kitty learns all about being a cat and a little about herself.</summary>
      <simplified:pwid>8c67e72f-234d-bc95-a617-6a20e7cbee9c</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250150141/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250150141/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Humorous%20Fiction" label="Humorous Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="7-8" label="7-8" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Roaring Brook</bib:publisherImprint>
      <dcterms:issued>2017-01-03</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019999304/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0019999304</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019999304" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2018-08-07T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T17:15:17+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019999304/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0019999304/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0019999304" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0019999304/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Daddy Depot</title>
      <contributor opf:role="ill">
         <name>Snair, Andy</name>
      </contributor>
      <author>
         <name>Stiefel, Chana</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Stiefel, Chana" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Stiefel%2C%20Chana/eng/Children" />
      </author>
      <summary type="html">Come to Daddy Depot: The Dad Megastore! From Acrobats to Zookeepers, we have the perfect dad for you! Exchange your old dad for a brand-new one . . . TODAY!Lizzie loves her dad, but he tells the same old jokes, falls asleep during story time, and gets distracted by football while Lizzie does her ballet twirls. When she sees an ad for a store called Daddy Depot, she decides to check it out—and finds dads of all kinds! Will Lizzie find the perfect dad? Join her on this sweet and silly adventure that celebrates fathers with lots of love.</summary>
      <simplified:pwid>d43c5569-7000-55bb-1438-3bf8b0c87b94</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250167576/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250167576/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Feiwel &amp; Friends</bib:publisherImprint>
      <dcterms:issued>2017-05-16</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020506628/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0020506628</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020506628" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2018-08-07T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:24:05+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020506628/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0020506628/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0020506628" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0020506628/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>A Magical Friend</title>
      <author>
         <name>Ryder, Chloe</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Ryder, Chloe" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Ryder%2C%20Chloe/eng/Children" />
      </author>
      <schema:Series name="Princess Ponies">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Princess Ponies" href="https://qa-circulation.openebooks.us/USOEI/works/series/Princess%20Ponies/eng/Children" />
      </schema:Series>
      <summary type="html">"On an enchanted island, far, far away, princess ponies can talk and play. Eight golden horseshoes give the ponies their magic, but when the shoes go missing from the castle, only a true pony lover can save the princesses and their home. Can Pippa and Stardust work together to find the golden horseshoes?"--</summary>
      <simplified:pwid>84707b2c-7b9d-f288-c047-c9707389003c</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781619631663/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781619631663/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="7-8" label="7-8" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Bloomsbury USA</dcterms:publisher>
      <bib:publisherImprint>Bloomsbury Childrens Books</bib:publisherImprint>
      <dcterms:issued>2014-03-04</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014502746/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0014502746</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014502746" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-09-01T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:01:49+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014502746/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014502746/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0014502746" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0014502746/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>The Whale Who Won Hearts</title>
      <author>
         <name>Zoehfeld, Kathleen Weidner</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Zoehfeld, Kathleen Weidner" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Zoehfeld%2C%20Kathleen%20Weidner/eng/Children" />
      </author>
      <author>
         <name>Skerry, Brian</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Skerry, Brian" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Skerry%2C%20Brian/eng/Children" />
      </author>
      <schema:Series name="Ngk Chapters">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Ngk Chapters" href="https://qa-circulation.openebooks.us/USOEI/works/series/Ngk%20Chapters/eng/Children" />
      </schema:Series>
      <summary type="html">National Geographic Kids Chapters picks up where the best-selling National Geographic Readers series leaves off. This new series offers young animal lovers who are ready for short chapters lively, full-color true stories just right to carry in a backpack, share with friends, and read under the covers at night. Based on the hit feature in National Geographic Kids magazine, we bring you Animal Adventures! Featuring three amazing stories of explorer Brian Skerry and his incredible encounters with animals, written in fun and lively prose that empowers readers to devour page after page.</summary>
      <simplified:pwid>a94b6621-9c1d-0a70-eefd-242a6bdace9c</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781426316067/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781426316067/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Nonfiction" label="Nonfiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Nature" label="Nature" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="7-8" label="7-8" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Random House Digital Dist</dcterms:publisher>
      <bib:publisherImprint>Natl Geographic Soc Childrens books</bib:publisherImprint>
      <dcterms:issued>2014-06-10</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014266757/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0014266757</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014266757" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2015-09-22T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:15:55+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014266757/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014266757/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0014266757" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0014266757/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>The Thing About Jellyfish</title>
      <author>
         <name>Benjamin, Ali</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Benjamin, Ali" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Benjamin%2C%20Ali/eng/Children" />
      </author>
      <summary type="html">Twelve-year-old Suzy Swanson wades through her intense grief over the loss of her best friend by investigating the rare jellyfish she is convinced was responsible for her friend's death.</summary>
      <simplified:pwid>7ca87ccf-b5fa-4089-0d99-df8758813dd4</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316389587/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316389587/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Hachette Book Group USA</dcterms:publisher>
      <bib:publisherImprint>Little Brown &amp; Co</bib:publisherImprint>
      <dcterms:issued>2015-09-22</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016870381/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016870381</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016870381" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2017-12-19T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:22:37+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016870381/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016870381/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016870381" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016870381/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Alistair and Kip's Great Adventure!</title>
      <contributor opf:role="ill">
         <name>Segal, John</name>
      </contributor>
      <author>
         <name>Segal, John</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Segal, John" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Segal%2C%20John/eng/Children" />
      </author>
      <summary type="html">One bossy cat. One nervous beagle. Two best friends. When Alistair and Kip set out to sea, they quickly find themselves in deep water.   Who will come to the rescue?   Join them and have a whale of a time in John Segal's charming tale of adventure and friendship.</summary>
      <simplified:pwid>fa615181-0294-c796-ec90-a1239106e0cd</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442427006/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781442427006/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Humorous%20Fiction" label="Humorous Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="5-6" label="5-6" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Margaret K McElderry</bib:publisherImprint>
      <dcterms:issued>2010-11-16</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016680097/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016680097</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016680097" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-15T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:11:02+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016680097/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016680097/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016680097" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016680097/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>The Black Cauldron 50th Anniversary Edition</title>
      <author>
         <name>Alexander, Lloyd</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Alexander, Lloyd" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Alexander%2C%20Lloyd/eng/Children" />
      </author>
      <schema:Series name="The Chronicles of Prydain">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="The Chronicles of Prydain" href="https://qa-circulation.openebooks.us/USOEI/works/series/The%20Chronicles%20of%20Prydain/eng/Children" />
      </schema:Series>
      <summary type="html">Henry Holt Books for Young Readers is proud to publish this 50th Anniversary Edition of Lloyd Alexander's classic Newbery Honor winner The Black Cauldron, the second book in the Chronicles of Prydain, with a new introduction by fellow Newbery Honor winner Rebecca Stead. In the land of Prydain, evil is never far away. Arawn, Lord of the Land of Death, has been building an army of dark warriors to take over Prydain, and the only way to stop him is to destroy the Black Cauldron he uses to create his dreaded soldiers. Taran the Assistant Pig-Keeper and his loyal companions must journey deep into Arawn's domain to destroy the Black Cauldron. For each of them, the quest has a special meaning.  For Taran, it is the glorious opportunity to use his first sword in battle.  But war requires a sacrifice greater than he'd ever imagined. . . .</summary>
      <simplified:pwid>9d3c10b9-4b87-efdb-9d89-7c7b1a20485f</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="https://covers.nypl.org/Content%20Cafe/ISBN/9781627795449/cover.jpg" type="image/jpeg" />
      <link rel="http://opds-spec.org/image/thumbnail" href="https://covers.nypl.org/scaled/300/Content%20Cafe/ISBN/9781627795449/cover.jpg" type="image/jpeg" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Folklore" label="Folklore" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Adventure" label="Adventure" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Henry Holt &amp; Co</bib:publisherImprint>
      <dcterms:issued>2015-09-01</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016531996/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016531996</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016531996" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2017-01-04T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:21:30+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016531996/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016531996/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016531996" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016531996/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Kip Campbell's Gift</title>
      <author>
         <name>Paratore, Coleen</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Paratore, Coleen" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Paratore%2C%20Coleen/eng/Children" />
      </author>
      <schema:Series name="The Funeral Director's Son">
         <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="The Funeral Director's Son" href="https://qa-circulation.openebooks.us/USOEI/works/series/The%20Funeral%20Director%27s%20Son/eng/Children" />
      </schema:Series>
      <summary type="html">Kip Campbell is still living in the town of Clover, still working in his family&amp;amp;;s funeral home, and yes, still speaking to the dead. Those souls who have passed on but aren&amp;amp;;t yet able to go to &amp;amp;;the good&amp;amp;; because something in their life was never resolved ask Kip for his help. As if these two jobs weren&amp;amp;;t enough, Kip also has to deal with girls and bullies. Now Kip is fed up and wants to quit talking to the dead, but can someone quit a job they never applied for?</summary>
      <simplified:pwid>81fa274b-0fd6-4f9f-e29a-94f19bf7e3c3</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781416996330/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781416996330/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
      <bib:publisherImprint>Simon &amp; Schuster Books for Young</bib:publisherImprint>
      <dcterms:issued>2009-08-04</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676963/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016676963</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676963" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-14T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:09:38+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676963/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016676963/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016676963" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016676963/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>The Fake Cape Caper</title>
      <contributor opf:role="ill">
         <name>Montijo, Rhode</name>
      </contributor>
      <author>
         <name>Trine, Greg</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Trine, Greg" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Trine%2C%20Greg/eng/Children" />
      </author>
      <summary type="html">When Melvin goes to Las Vegas for the Superhero's Convention, Candace is left in charge of Los Angeles. Between the horrible bullies at school and the devious and sinister Spaz brothers (Major and his brother, Big), Candace has her hands very full. And someone has stolen her cape! How will she stop all the bad guys before the city is overrun with crime?The super-sidekick gets her turn in the spotlight in this fifth hilarious book in the Melvin Beederman series.</summary>
      <simplified:pwid>977c4594-f0bf-0662-5216-5f379168fcc2</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="https://covers.nypl.org/Content%20Cafe/ISBN/9781250104748/cover.jpg" type="image/jpeg" />
      <link rel="http://opds-spec.org/image/thumbnail" href="https://covers.nypl.org/scaled/300/Content%20Cafe/ISBN/9781250104748/cover.jpg" type="image/jpeg" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Comics%20%26%20Graphic%20Novels" label="Comics &amp; Graphic Novels" />
      <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Humorous%20Fiction" label="Humorous Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Macmillan</dcterms:publisher>
      <bib:publisherImprint>Square Fish</bib:publisherImprint>
      <dcterms:issued>2015-11-24</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017787292/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0017787292</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017787292" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2017-01-04T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:22:13+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017787292/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017787292/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0017787292" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0017787292/open_book" />
   </entry>
   <entry schema:additionalType="http://schema.org/EBook">
      <title>Megan 3</title>
      <author>
         <name>Hooper, Mary</name>
         <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hooper, Mary" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hooper%2C%20Mary/eng/Children" />
      </author>
      <summary type="html">It is Jack's , Megan's son, first birthday party and the atmosphere is very tense. But Megan is being distracted by the unusual amount of telephone calls that her mother is getting. Surely Mum hasn't got a boyfriend has she? But it turns out that she has - and what is more, he is moving in. This really shakes up the dynamics of the house-hold, which Mary Hooper details with her accustomed very acccurate and precise eye for domestic detail. A must for all Megan fans.</summary>
      <simplified:pwid>42a795cb-8f98-645c-8819-bf9904c4ae30</simplified:pwid>
      <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781408836798/Large/Logo" type="image/png" />
      <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781408836798/Medium/Logo" type="image/png" />
      <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
      <category scheme="http://schema.org/audience" term="Children" label="Children" />
      <category scheme="http://schema.org/typicalAgeRange" term="8-9" label="8-9" />
      <dcterms:language>en</dcterms:language>
      <dcterms:publisher>Bloomsbury Pub Plc</dcterms:publisher>
      <bib:publisherImprint>Bloomsbury Childrens Books</bib:publisherImprint>
      <dcterms:issued>2012-09-13</dcterms:issued>
      <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014213671/report" />
      <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0014213671</id>
      <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014213671" type="application/atom+xml;type=entry;profile=opds-catalog" />
      <bibframe:distribution bibframe:ProviderName="Axis 360" />
      <published>2016-01-15T00:00:00Z</published>
      <link rel="collection" href="https://qa-circulation.openebooks.us/USOEI/groups/429?entrypoint=Book" title="Early Grades" />
      <updated>2022-01-04T04:16:20+00:00</updated>
      <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014213671/borrow/15">
         <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
         <opds:availability status="available" />
         <opds:holds total="0" />
         <opds:copies total="9999" available="9999" />
      </link>
      <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0014213671/related_books" />
      <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0014213671" />
      <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0014213671/open_book" />
   </entry>
   <link rel="start" href="https://qa-circulation.openebooks.us/USOEI/groups/" title="All Books" />
   <link rel="http://opds-spec.org/auth/document" href="https://qa-circulation.openebooks.us/USOEI/authentication_document" />
   <link rel="search" type="application/opensearchdescription+xml" href="https://qa-circulation.openebooks.us/USOEI/search/?entrypoint=All" />
   <link rel="http://opds-spec.org/shelf" type="application/atom+xml;profile=opds-catalog;kind=acquisition" href="https://qa-circulation.openebooks.us/USOEI/loans/" />
   <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/" />
   <link href="http://openebooks.net/index.html" title="Home" type="text/html" rel="related" role="navigation" />
   <link href="http://openebooks.net/about.html" title="About" type="text/html" rel="related" role="navigation" />
   <link href="http://openebooks.net/faq.html" title="FAQs" type="text/html" rel="related" role="navigation" />
   <link href="http://openebooks.net/resources.html" title="Resources" type="text/html" rel="related" role="navigation" />
   <link href="https://openebooks.zendesk.com/" title="Help Center" type="text/html" rel="related" role="navigation" />
   <link href="mailto:leonardrichardson@nypl.org" rel="help" />
</feed>
`;

export default groups;
