const booksByAuthorHunterCC = `
<feed xmlns="http://www.w3.org/2005/Atom" xmlns:app="http://www.w3.org/2007/app" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:opds="http://opds-spec.org/2010/catalog" xmlns:opf="http://www.idpf.org/2007/opf" xmlns:drm="http://librarysimplified.org/terms/drm" xmlns:schema="http://schema.org/" xmlns:simplified="http://librarysimplified.org/terms/" xmlns:bibframe="http://bibframe.org/vocab/" xmlns:bib="http://bib.schema.org/" xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" xmlns:lcp="http://readium.org/lcp-specs/ns" simplified:entryPoint="http://schema.org/EBook">
  <id>https://qa-circulation.openebooks.us/USOEI/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full&amp;size=50</id>
  <title>Hunter, C. C.</title>
  <updated>2022-01-04T14:08:29+00:00</updated>
  <link href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full&amp;size=50" rel="self"/>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Almost Midnight</title>
    <author>
      <name>Hunter, C. C.</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hunter, C. C." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="Shadow Falls: After Dark">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Shadow Falls: After Dark" href="https://qa-circulation.openebooks.us/USOEI/works/series/Shadow%20Falls:%20After%20Dark/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">A vampire and a werewolf who never fit in. A witch whose spells don't always work as planned. And a mysterious new guy who's hiding something. Together they will discover who they're meant to be...Hidden from the human world, Shadow Falls is a secret camp for teens with supernatural powers. But for four very different teens, it's much more than just a camp-it's where their destinies will unfold.Independent and strong-willed Della Tsang didn't believe in vampires...until she became one. Chase Tallman is the newest member of Shadow Falls, but what made him into the sexy, mysterious vampire he is today? And what led him to Della Tsang? For Miranda Kane, magic has always been something she's struggled with-until she finally has a chance to prove herself a witch to be reckoned with. And for Fredericka Lakota, the one guy who challenges her the most will lead her to the life she's always wanted.C. C. Hunter's New York Times bestselling Shadow Falls series captured hearts all over the world. Now available in print for the first time, these enchanting novellas will reveal a side of the camp that you've never seen before...and live in your heart forever. Fans won't want to miss these four remarkable stories of love, magic and friendship in Almost Midnight.</summary>
    <simplified:pwid>e2907ae6-0cff-a26a-90ec-2bb676a39efb</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466892859/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781466892859/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Suspense/Thriller" label="Suspense/Thriller"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Paranormal%20Romance" label="Paranormal Romance"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>St Martins Pr Griffin</bib:publisherImprint>
    <dcterms:issued>2016-02-02</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0017409891</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2022-01-04T04:25:19+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0017409891/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0017409891"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0017409891/open_book"/>
  </entry>
  <entry schema:additionalType="http://schema.org/EBook">
    <title>Midnight Hour</title>
    <author>
      <name>Hunter, C. C.</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Hunter, C. C." href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </author>
    <schema:Series name="A Shadow Falls Novel">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="A Shadow Falls Novel" href="https://qa-circulation.openebooks.us/USOEI/works/series/A%20Shadow%20Falls%20Novel/eng/All%2BAges%2CChildren%2CYoung%2BAdult"/>
    </schema:Series>
    <summary type="html">“Shadow Falls has everything I could wish for in a series…a thrilling tale of self-discovery, friendship and love.” —Bewitched BookwormAfter captivating millions across the globe, the Shadow Falls saga reaches its final—and most unforgettable—chapter yet! Prepare to fall in love all over again. . .Being a dyslexic witch is a curse in itself, but Miranda Kane’s time at Shadow Falls has helped her harness her magical powers. Now, just as she’s finally mastered them and is preparing to graduate with her friends, a near-death experience threatens to ruin it all.Miranda awakens in the hospital with a mysterious tattoo that no one can explain. As she struggles to make sense of it – and questions her feelings for a certain irresistible shape-shifter and a hot new guy – the strange markings begin to spread all over her body, leaving her desperate to find answers. But before she can solve that problem, a new one arises: her sister is missing.Has her sister been kidnapped? Miranda will risk her life to find out. Will she live to share the day she’s worked so hard for with her friends? When the clock strikes midnight, will Miranda make it to her graduation at Shadow Falls?C. C. Hunter brings The Shadow Falls saga to a spectacular conclusion in this thrilling finale: Midnight Hour!</summary>
    <simplified:pwid>5f470d11-2df9-2c50-06d9-6696f7ced18d</simplified:pwid>
    <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250035875/Large/Logo" type="image/png"/>
    <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781250035875/Medium/Logo" type="image/png"/>
    <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Fantasy" label="Fantasy"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Paranormal%20Romance" label="Paranormal Romance"/>
    <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Suspense/Thriller" label="Suspense/Thriller"/>
    <category scheme="http://schema.org/audience" term="Young Adult" label="Young Adult"/>
    <category scheme="http://schema.org/typicalAgeRange" term="12-14" label="12-14"/>
    <dcterms:language>en</dcterms:language>
    <dcterms:publisher>Macmillan</dcterms:publisher>
    <bib:publisherImprint>St Martins Pr Griffin</bib:publisherImprint>
    <dcterms:issued>2016-10-25</dcterms:issued>
    <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018642364/report"/>
    <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0018642364</id>
    <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018642364" type="application/atom+xml;type=entry;profile=opds-catalog"/>
    <bibframe:distribution bibframe:ProviderName="Axis 360"/>
    <published>2018-08-07T00:00:00Z</published>
    <updated>2022-01-04T04:26:39+00:00</updated>
    <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018642364/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json"/>
      <opds:availability status="available"/>
      <opds:holds total="0"/>
      <opds:copies total="9999" available="9999"/>
    </link>
    <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0018642364/related_books"/>
    <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0018642364"/>
    <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0018642364/open_book"/>
  </entry>
  <link href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full" title="Title" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by" opds:activeFacet="true"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult?entrypoint=Book&amp;order=author&amp;available=now&amp;collection=full" title="Author" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult?entrypoint=Book&amp;order=added&amp;available=now&amp;collection=full" title="Recently Added" rel="http://opds-spec.org/facet" opds:facetGroup="Sort by"/>
  <link rel="next" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult?entrypoint=Book&amp;order=title&amp;available=now&amp;collection=full&amp;key=%5B%22%5Cu209c%5Cu4bd0%5Cu6726%5Cu5374%5Cu7821%5Cu5d15%5Cu2296%5Cu0111%5Cu00ee%5Cu2fb7%5Cu00e0%5Cu0000%5Cu0000%22%2C+%22%5Cu1ba8%5Cu50d3%5Cu6629%5Cu3060%5Cu2169%5Cu3404%5Cu1c03%5Cu5cbf%5Cu6e6e%5Cu0000%5Cu0001%22%2C+15239%5D&amp;size=50"/>
  <link rel="start" href="https://qa-circulation.openebooks.us/USOEI/groups/" title="All Books"/>
  <simplified:breadcrumbs>
    <link title="All Books" href="https://qa-circulation.openebooks.us/USOEI/groups/"/>
    <link title="Book" href="https://qa-circulation.openebooks.us/USOEI/groups/?entrypoint=Book"/>
  </simplified:breadcrumbs>
  <link rel="http://opds-spec.org/auth/document" href="https://qa-circulation.openebooks.us/USOEI/authentication_document"/>
  <link rel="search" type="application/opensearchdescription+xml" href="https://qa-circulation.openebooks.us/USOEI/search/?entrypoint=All&amp;order=title&amp;available=now&amp;collection=full"/>
  <link rel="http://opds-spec.org/shelf" type="application/atom+xml;profile=opds-catalog;kind=acquisition" href="https://qa-circulation.openebooks.us/USOEI/loans/"/>
  <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/"/>
  <link href="http://openebooks.net/index.html" title="Home" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/about.html" title="About" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/faq.html" title="FAQs" type="text/html" rel="related" role="navigation"/>
  <link href="http://openebooks.net/resources.html" title="Resources" type="text/html" rel="related" role="navigation"/>
  <link href="https://openebooks.zendesk.com/" title="Help Center" type="text/html" rel="related" role="navigation"/>
  <link href="mailto:leonardrichardson@nypl.org" rel="help"/>
</feed>
`;

export default booksByAuthorHunterCC;
