const bookJohnFKennedy = `
<?xml version="1.0" encoding="UTF-8"?>
<entry xmlns="http://www.w3.org/2005/Atom" xmlns:app="http://www.w3.org/2007/app" xmlns:bib="http://bib.schema.org/" xmlns:bibframe="http://bibframe.org/vocab/" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:drm="http://librarysimplified.org/terms/drm" xmlns:lcp="http://readium.org/lcp-specs/ns" xmlns:opds="http://opds-spec.org/2010/catalog" xmlns:opensearch="http://a9.com/-/spec/opensearch/1.1/" xmlns:opf="http://www.idpf.org/2007/opf" xmlns:schema="http://schema.org/" xmlns:simplified="http://librarysimplified.org/terms/" schema:additionalType="http://schema.org/EBook">
   <title>John F. Kennedy</title>
   <contributor opf:role="ill">
      <name>Fiorentino, Al</name>
   </contributor>
   <author>
      <name>Frisbee, Lucy Post</name>
      <link rel="contributor" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Frisbee, Lucy Post" href="https://qa-circulation.openebooks.us/USOEI/works/contributor/Frisbee%2C%20Lucy%20Post/eng/Children" />
   </author>
   <schema:Series name="History's All-stars">
      <link rel="series" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="History's All-stars" href="https://qa-circulation.openebooks.us/USOEI/works/series/History%27s%20All-stars/eng/Children" />
   </schema:Series>
   <summary type="html">When young Jack Kennedy's little sister gets tired of playing, Jack suggests a a sail. Within moments Jack is steering the small chip toward Osterville to say hello to Captain Manley. Under darkening clouds the two children head back home -- but they do not make it before a storm hits, threatening their lives. Is young Jack a good enough sailor to bring his sister home to safety?</summary>
   <simplified:pwid>f23dff14-b1c0-3bdb-d8c6-63e406dc9b2a</simplified:pwid>
   <link rel="http://opds-spec.org/image" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781481430777/Large/Logo" type="image/png" />
   <link rel="http://opds-spec.org/image/thumbnail" href="http://contentcafecloud.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781481430777/Medium/Logo" type="image/png" />
   <category scheme="http://librarysimplified.org/terms/fiction/" term="http://librarysimplified.org/terms/fiction/Fiction" label="Fiction" />
   <category scheme="http://librarysimplified.org/terms/genres/Simplified/" term="http://librarysimplified.org/terms/genres/Simplified/Historical%20Fiction" label="Historical Fiction" />
   <category scheme="http://schema.org/audience" term="Children" label="Children" />
   <category scheme="http://schema.org/typicalAgeRange" term="9-11" label="9-11" />
   <dcterms:language>en</dcterms:language>
   <dcterms:publisher>Simon &amp; Schuster</dcterms:publisher>
   <bib:publisherImprint>Aladdin</bib:publisherImprint>
   <dcterms:issued>2015-01-13</dcterms:issued>
   <link rel="issues" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239/report" />
   <id>urn:librarysimplified.org/terms/id/Axis%20360%20ID/0016692239</id>
   <link rel="alternate" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239" type="application/atom+xml;type=entry;profile=opds-catalog" />
   <bibframe:distribution bibframe:ProviderName="Axis 360" />
   <published>2016-01-20T00:00:00Z</published>
   <updated>2022-01-13T04:13:53+00:00</updated>
   <link type="application/atom+xml;type=entry;profile=opds-catalog" rel="http://opds-spec.org/acquisition/borrow" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239/borrow/15">
      <opds:indirectAcquisition type="application/vnd.librarysimplified.axisnow+json" />
      <opds:availability status="available" />
      <opds:holds total="0" />
      <opds:copies total="9999" available="9999" />
   </link>
   <link rel="related" type="application/atom+xml;profile=opds-catalog;kind=acquisition" title="Recommended Works" href="https://qa-circulation.openebooks.us/USOEI/works/Axis%20360%20ID/0016692239/related_books" />
   <link rel="http://www.w3.org/ns/oa#annotationService" type="application/ld+json; profile=&quot;http://www.w3.org/ns/anno.jsonld&quot;" href="https://qa-circulation.openebooks.us/USOEI/annotations/Axis%20360%20ID/0016692239" />
   <link rel="http://librarysimplified.org/terms/rel/analytics/open-book" href="https://qa-circulation.openebooks.us/USOEI/analytics/Axis%20360%20ID/0016692239/open_book" />
</entry>
`;

export default bookJohnFKennedy;
