const tocAlmostMidnight = `
<?xml version="1.0" encoding="UTF-8"?>
<ncx version="2005-1" xml:lang="en-US" xmlns="http://www.daisy.org/z3986/2005/ncx/">
<head>
<meta name="dtb:uid" content="urn:isbn:9781466892859"/>
<meta name="dtb:depth" content="1"/>
<meta name="dtb:totalPageCount" content="417"/>
<meta name="dtb:maxPageNumber" content="0"/>
</head>
<docTitle>
<text>Almost Midnight</text>
</docTitle>
<docAuthor><text>C. C. Hunter</text></docAuthor>
<navMap>
<navPoint class="other" id="navpoint-1" playOrder="1"><navLabel><text>Cover</text></navLabel><content src="xhtml/cover.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-2" playOrder="2"><navLabel><text>Title Page</text></navLabel><content src="xhtml/title.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-3" playOrder="3"><navLabel><text>Copyright Notice</text></navLabel><content src="xhtml/copyrightnotice.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-4" playOrder="4"><navLabel><text>Dear Reader</text></navLabel><content src="xhtml/reader.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-5" playOrder="5"><navLabel><text>Turned at Dark</text></navLabel><content src="xhtml/part1.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-6" playOrder="6"><navLabel><text>Saved at Sunrise</text></navLabel><content src="xhtml/part2.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-7" playOrder="7"><navLabel><text>Unbreakable</text></navLabel><content src="xhtml/part3.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-8" playOrder="8"><navLabel><text>Spellbinder</text></navLabel><content src="xhtml/part4.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-9" playOrder="9"><navLabel><text>Fierce</text></navLabel><content src="xhtml/part5.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-10" playOrder="10"><navLabel><text>About the Author</text></navLabel><content src="xhtml/abouttheauthor.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-11" playOrder="11"><navLabel><text>Also by C. C. Hunter</text></navLabel><content src="xhtml/adcard.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-12" playOrder="12"><navLabel><text>Praise for the Author</text></navLabel><content src="xhtml/praise.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-13" playOrder="13"><navLabel><text>Newsletter Sign-up</text></navLabel><content src="xhtml/newsletter.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-14" playOrder="14"><navLabel><text>Contents</text></navLabel><content src="xhtml/contents.xhtml"/></navPoint>
<navPoint class="other" id="navpoint-15" playOrder="15"><navLabel><text>Copyright</text></navLabel><content src="xhtml/copyright.xhtml"/></navPoint>
</navMap>
</ncx>`;

export default tocAlmostMidnight;
